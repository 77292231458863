import { IBreakpoint } from './types';

const GRID_UNIT: number = 8;

const BREAKPOINTS: IBreakpoint = {
  lg: 1280,
  md: 960,
  sm: 640,
  xs: 0,
};

export {
  GRID_UNIT,
  BREAKPOINTS,
};
