import React, { useEffect, useState } from 'react';
import pageConstructor from 'shared/pageElements/pageConstructor';
import theme from 'shared/theme';
import useAppState from 'state';
import { motion } from 'framer-motion';
import useFirebase from 'vendor/Firebase';
import { IContentPageParams } from './types';
import SiteFooter from '../components/pageSections/SiteFooter';
import { scrollToHash } from '../shared/scroll';

const ContentPage = ({
  name, firebaseRef, placeholder = <div />,
}: IContentPageParams) => {
  const { uiState } = useAppState();

  const { analytics, functions } = useFirebase();
  const [PageContent, setPageContent] = useState(placeholder);
  const isMobile = window.innerWidth < parseInt(theme.deviceSizes.laptop, 10);
  const pageTransitions = {
    out: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
  };

  useEffect(() => {
    uiState.loading = true;
    analytics.logEvent('page_view', { value: name });
    functions.httpsCallable(`api-v1-pages-${firebaseRef}`)({})
      .then((result: any) => {
        uiState.loading = false;
        setPageContent(
          <>
            {pageConstructor(result.data)}
          </>,
        );

        if (window.location.hash.length > 0) {
          scrollToHash(window.location.hash);
        } else {
          uiState.scrollPosition = 0;
          scrollToHash('');
        }
      })
      .catch((e) => console.log(e));
  }, [firebaseRef]);

  return (
    <motion.div
      initial="out"
      animate="in"
      exit="out"
      variants={pageTransitions}
      transition={{ ease: theme.easing.easeCurves, duration: isMobile ? 0 : 0.35 }}
    >
      {PageContent}
      <SiteFooter />
    </motion.div>
  );
};

export default ContentPage;
