import styled from 'styled-components';
import { Body, Caption, H1 } from 'shared/typography';
import { rgba } from 'shared/palette/utils';
import theme from 'shared/theme';
import { FlexColumn } from 'shared/containers/FlexContainer';
import { IHero, IHeroOuterWrapper } from './types';

export const HeroContainer = styled(FlexColumn)`
  width: 100%;
  height: calc(100vh - ${theme.spacing(10)});
  
  align-items: flex-start;
  justify-content: flex-end;
  position: relative;
  
  padding-bottom: ${theme.spacing(16)};
  
  opacity: ${(props: IHero) => (props.backgroundImage.length > 0 ? 1 : 0)};
  transition: opacity 0.5s ease-in;
 
  &::after {
    width: 100%;
    height: 75%;
    
    content: '';
    
    position: absolute;
    z-index: 1;
    bottom: 0;
    
    background: linear-gradient(0deg, ${theme.palette.neutral.black}, transparent);  
    opacity: ${(props: IHero) => (props.backgroundImage.length > 0 ? 0.8 : 0)};
    transition: opacity 0.5s ease-in;
  }  
  
  &::before {
    width: 100%;
    height: 100%;
    
    content: '';
    
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index; 0;
    
    opacity: 1;
    background-image: url(${(props: IHeroOuterWrapper) => props.backgroundImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; 
  }
  
  @media ${theme.device.mobileM} {
    height: calc(100vh - ${theme.spacing(16)});
  }
  
  @media ${theme.device.laptopL} {
    height: calc(80vh - ${theme.spacing(16)});
    &::after {
      height: 100%;
      opacity: 0.5
    }
  }
  
`;

export const HeroInnerWrapper = styled.div`
  width: 100%;
  height: auto;
  
  position: relative;
  z-index: 2;
  
  padding: ${theme.spacing(3)} 0;
`;

export const HeroInnerContainer = styled.div`
  width: calc(100% - ${theme.spacing(6)});
  max-width: ${theme.deviceSizes.tablet};
  
  z-index: 1;
  
  margin: 0 auto;
  
  @media ${theme.device.laptop} {
    min-width: ${theme.spacing(114)}; 
  }
  @media ${theme.device.laptopL} {
    min-width: ${theme.maxWidth};
  }
`;

export const HeroBodyWrapper = styled.div`
  max-width: ${theme.spacing(80)};
`;

export const HeroHeader = styled(H1)`
  max-width: 100%;
  
  color: ${theme.palette.neutral.white};
  text-shadow: 1px 1px 4px ${rgba(theme.palette.neutral.black, 0.2)};
  
  margin-top: 0;
  margin-bottom: ${theme.spacing(2)};
  
  @media ${theme.device.tablet} {
    max-width: 80%;
  }
  
  @media ${theme.device.laptop} {
    max-width: 100%;
  }
  
  @media ${theme.device.laptopL} {
    max-width: 77%;
  }
`;

export const HeroPageTitle = styled(Caption)`
  width: fit-content;
  
  font-weight: bold;
  text-transform: uppercase;
  color: ${theme.palette.neutral.black};
  background-color: ${theme.palette.neutral.white};
  
  padding: 0 ${theme.spacing(0.5)};
`;

export const HeroBody = styled(Body)`
  color: ${theme.palette.neutral.white};
  
  & a {
    color: ${theme.palette.neutral.white};
    
    &:visited {
      color: ${theme.palette.neutral.white};
    }
  }
`;
