import { computed, decorate, observable } from 'mobx';

class ContactFormState {
  email: {
    value: string;
    valid: boolean;
  };

  name: {
    value: string;
    valid: boolean;
  };

  company: {
    value: string;
    valid: boolean;
  };

  websiteUrl: {
    value: string;
    valid: boolean;
  };

  inProduction: {
    value: boolean|null;
  };

  bodyQuantity: {
    value: number|null;
  };

  neckQuantity: {
    value: number|null;
  };

  notes: {
    value: string;
  };

  consent: {
    value: boolean;
  };

  constructor() {
    this.email = { value: '', valid: false };
    this.name = { value: '', valid: false };
    this.company = { value: '', valid: false };
    this.websiteUrl = { value: '', valid: false };
    this.inProduction = { value: null };
    this.bodyQuantity = { value: null };
    this.neckQuantity = { value: null };
    this.notes = { value: '' };
    this.consent = { value: false };
  }

  get formInvalid() {
    return !this.email.valid
    || !this.name.valid
    || !this.company.valid
    || !this.websiteUrl.valid
    || this.inProduction.value === null
    || this.bodyQuantity.value === null
    || this.neckQuantity.value === null
    || !this.consent.value;
  }
}

decorate(ContactFormState, {
  email: observable,
  name: observable,
  consent: observable,
  company: observable,
  websiteUrl: observable,
  inProduction: observable,
  bodyQuantity: observable,
  neckQuantity: observable,
  notes: observable,
  formInvalid: computed,
});

export default ContactFormState;
