import styled from 'styled-components';
import { FlexColumn } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';

export const FooterColumn = styled(FlexColumn)`
  width: calc(100vw - ${theme.spacing(6)});
  position: relative;
  
  justify-content: center;
  align-items: flex-start;
  
  &::before {
    content: '';
    width: 100%;
    height: 1px;
    
    background-color: ${theme.palette.neutral.white};
    
    position: absolute;
    top: -1px;
  }
  
  padding: ${theme.spacing(3)} 0;
  
  @media ${theme.device.laptop} {
    width: 100vw;
    max-width: ${theme.maxWidth};
    padding: ${theme.spacing(6)} 0;
  }
`;

export const FooterTextWrapper = styled.div`
  position: relative;
  left: ${theme.spacing(1)};
`;
