import React, { useEffect, useState } from 'react';
import { SectionCta } from 'components/buttons/SectionCta';
import ScrollAnimation from 'react-animate-on-scroll';
import useAppState from 'state';
import { IUIState } from 'state/states/UIState';
import useFirebase from 'vendor/Firebase';
import { ITopicSection } from './types';
import {
  TopicSectionOuterWrapper,
  TopicSectionContainer,
  SectionHeader,
  SectionBodyWrapper,
  SectionBody,
  TopicSectionOuterWrapperAutoHeight,
} from './styledComponents';

const shortid = require('shortid');

const sectionAttributes = (uiState: IUIState, noAnimation?: boolean, invert?: boolean) => {
  const animation = noAnimation ? 'none' : uiState.sectionAnimation;
  const textColor = invert ? uiState.textColor.inverted : uiState.textColor.primary;
  const textShadow = invert ? uiState.textShadow.inverted : uiState.textColor.primary;

  return [animation, textColor, textShadow];
};

const TopicSection = ({
  title, bodyText, backgroundImage, cta,
  style, noAnimation, invert, id, lastOfPage, autoHeight,
}: ITopicSection) => {
  const { configState, uiState } = useAppState();
  const { firebase } = useFirebase();
  const [animation, textColor, textShadow] = sectionAttributes(uiState, noAnimation, invert);

  const SectionWrapper = autoHeight ? TopicSectionOuterWrapperAutoHeight : TopicSectionOuterWrapper;

  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (!backgroundImage) return;
    firebase.storage().ref(configState.storageRoot).child(backgroundImage || '')
      .getDownloadURL()
      .then((res: string) => {
        setImageUrl(res);
      });
  }, [backgroundImage]);

  return (
    <ScrollAnimation key={shortid.generate()} animateIn={animation} offset={0} duration={1} animateOnce>
      <SectionWrapper
        id={id}
        style={style}
        title={title}
        bodyText={bodyText}
        backgroundImage={imageUrl}
        lastOfPage={lastOfPage || false}
        invert={invert || false}
        autoHeight={autoHeight}
      >
        <TopicSectionContainer justify="center">
          <SectionHeader shadow={textShadow} color={textColor}>{title}</SectionHeader>
          {
            bodyText.split('\\n').map((text) => (
              <SectionBodyWrapper key={shortid.generate()}>
                <SectionBody color={textColor}>{text}</SectionBody>
              </SectionBodyWrapper>
            ))
          }
          {
            cta
            && <SectionCta shadow={textShadow} color={textColor} href={cta.href} label={cta.label} />

          }
        </TopicSectionContainer>
      </SectionWrapper>
    </ScrollAnimation>
  );
};

export {
  TopicSection,
};
