import { IEmployeeDetails } from 'components/feature/Employee/types';
import { IFAQItem } from 'components/feature/FAQItem/types';
import { IFeatureItem } from 'components/feature/FeatureItem/types';
import { IHero } from 'components/pageSections/Hero/types';
import { IImageSlide } from 'components/gallery/CarouselSlide/types';
import { ISectionCta } from 'components/buttons/SectionCta/types';

export interface IBaseType {
  id?: string;
  style?: object;
  className?: string;
}

export enum ContentType {
  CAROUSEL = 'CAROUSEL',
  CENTERED_TOPIC_SECTION = 'CENTERED_TOPIC_SECTION',
  CONTACT_FORM = 'CONTACT_FORM',
  EMPLOYEE_GROUP = 'EMPLOYEE_GROUP',
  FAQ_SECTION = 'FAQ_SECTION',
  FEATURE_POINT = 'FEATURE_POINT',
  FEATURE_ROW = 'FEATURE_ROW',
  FLEX_COLUMN = 'FLEX_COLUMN',
  FLOATING_IMAGE = 'FLOATING_IMAGE',
  HERO = 'HERO',
  HERO_FULL_BLEED = 'HERO_FULL_BLEED',
  TOPIC_SECTION = 'TOPIC_SECTION',
  STATIC_IMAGE = 'STATIC_IMAGE',
}

export interface IContentItem {
  id: string;
  title?: string;
  type: ContentType;
  data: IContentData;
}

export interface IContentData {
  align?: string;
  autoAdvance?: boolean;
  autoAdvanceDelay?: number;
  autoHeight?: boolean;
  backgroundImage?: string;
  body?: string;
  compact?: boolean;
  content?: Array<IContentItem>;
  cta?: ISectionCta;
  employees?: Array<IEmployeeDetails>;
  defaulItem?: string;
  faqItems?: Array<IFAQItem>;
  floatingImage?: boolean;
  floatingImageHeight?: string;
  id?: string;
  images?: Array<IImageSlide>;
  imagePinning?: 'left'|'center'|'right';
  imageUrl?: string;
  invert?: boolean;
  items?: Array<IFeatureItem>;
  justify?: string;
  lastOfPage?: boolean;
  manager?: boolean;
  noAnimation?: boolean;
  noSeparator?: boolean;
  owner?: boolean;
  parent?: string;
  pageTitle?: string;
  rowDirection?: string;
  showControls?: boolean;
  showPips?: boolean;
  style?: object;
  title?: string;
  top?: string;
}

export interface IApiPageResponse {
  hero: IHero
  content: Array<IContentItem>
}
