import styled from 'styled-components';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { FeatureTitle } from 'shared/pageElements/styledComponents';
import { rgba } from 'shared/palette/utils';
import { Body } from 'shared/typography';
import theme from 'shared/theme';
import { IEmployeeWrapper, IEmployeePhoto } from './types';

const photoRadius = (dimension: string): string => `${parseInt(dimension, 10) / 2}px`;

const photoSizes = {
  large: {
    owner: theme.spacing(44),
    manager: theme.spacing(40),
    employee: theme.spacing(32),
  },
  small: {
    owner: theme.spacing(32),
    manager: theme.spacing(28),
    employee: theme.spacing(24),
  },
};

export const EmployeeRow = styled(FlexRow)`
  max-width: ${theme.maxWidth};
  
  justify-content: space-around;
  
  margin: 0 auto;
`;

export const EmployeeWrapper = styled(FlexColumn)`
  width ${(props: IEmployeeWrapper) => props.width};
  margin-bottom: ${theme.spacing(6)};
  
  @media ${theme.device.tablet} {
    margin-bottom: ${theme.spacing(10)};
  }
  
  @media ${theme.device.laptop} {
    margin-bottom: ${theme.spacing(18)};
  } 
`;

export const EmployeePhoto = styled.div`
  width: ${(props: IEmployeePhoto) => (props.isOwner ? photoSizes.small.owner : photoSizes.small.manager)};
  height: ${(props: IEmployeePhoto) => (props.isOwner ? photoSizes.small.owner : photoSizes.small.manager)};
  border-radius: ${photoRadius(photoSizes.small.owner)};
  
  background-image: ${(props: IEmployeePhoto) => `url("${props.image}")`};
  background-size: cover;
  background-color: ${rgba(theme.palette.neutral.white, 0.15)};
  
  filter: grayscale(0.3);
  margin-bottom: ${theme.spacing(3)};
  
  &::before {
    content: '';
    width: ${(props: IEmployeePhoto) => (props.isOwner ? photoSizes.small.owner : photoSizes.small.manager)};
    height: ${(props: IEmployeePhoto) => (props.isOwner ? photoSizes.small.owner : photoSizes.small.manager)};
    border-radius: ${photoRadius(photoSizes.small.manager)};
    
    background-color: ${rgba(theme.palette.neutral[700], 0.3)};
    box-shadow: inset -1px -1px 12px rgba(0, 0, 0, 0.5);
  }
  
  @media ${theme.device.tablet} {
    width: ${(props: IEmployeePhoto) => (props.isManager
    ? photoSizes.small.manager : props.isOwner ? photoSizes.small.owner : photoSizes.small.employee)};
    height: ${(props: IEmployeePhoto) => (props.isManager
    ? photoSizes.small.manager : props.isOwner ? photoSizes.small.owner : photoSizes.small.employee)};
  }
  
  @media ${theme.device.laptop} {
    width: ${(props: IEmployeePhoto) => (props.isManager
    ? photoSizes.large.manager : props.isOwner ? photoSizes.large.owner : photoSizes.large.employee)};
    height: ${(props: IEmployeePhoto) => (props.isManager
    ? photoSizes.large.manager : props.isOwner ? photoSizes.large.owner : photoSizes.large.employee)};
    border-radius: ${(props: IEmployeePhoto) => (props.isManager
    ? photoRadius(photoSizes.large.manager) : props.isOwner
      ? photoRadius(photoSizes.large.owner) : photoRadius(photoSizes.large.employee))};
    
    &::before {
      content: '';
      width: ${(props: IEmployeePhoto) => (props.isManager
    ? photoSizes.large.manager : props.isOwner ? photoSizes.large.owner : photoSizes.large.employee)};
      height: ${(props: IEmployeePhoto) => (props.isManager
    ? photoSizes.large.manager : props.isOwner ? photoSizes.large.owner : photoSizes.large.employee)};
      border-radius: ${(props: IEmployeePhoto) => (props.isManager
    ? photoRadius(photoSizes.large.manager) : props.isOwner
      ? photoRadius(photoSizes.large.owner) : photoRadius(photoSizes.large.employee))}; 
      
      background-color: ${rgba(theme.palette.neutral[700], 0.3)};
      box-shadow: inset -1px -1px 12px rgba(0, 0, 0, 0.5);
    }
  }
`;

export const EmployeeDetails = styled(FlexColumn)`
  padding: 0 ${theme.spacing(3)};
`;

export const EmployeeName = styled(FeatureTitle)`
  margin-bottom: 0;
  text-align: center;
  @media ${theme.device.tablet} {
    text-align: center;
  }
`;
export const EmployeeJobTitle = styled(Body)`
  color: ${theme.palette.neutral[500]};
  text-transform: uppercase;
  
  margin-top: ${theme.spacing(0.5)};
`;
