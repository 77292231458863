import styled from 'styled-components';
import { FlexRow } from 'shared/containers/FlexContainer';
import { ChevronBackCircle, ChevronForwardCircle } from '@styled-icons/ionicons-solid';
import theme from 'shared/theme';
import ScrollAnimation from 'react-animate-on-scroll';
import { IImagePip, ISlideControls, ISlideRow } from './types';

export const CarouselAnimationWrapper = styled(ScrollAnimation)`
  width: 100%;
  height: 100%;
`;

export const CarouselRow = styled(FlexRow)`
  width: 100vw;
  height: 60vh;
  
  @media ${theme.device.laptop} {
    height: 100vh
  }
`;

export const CarouselWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`;
CarouselWrapper.displayName = 'CarouselWrapper';

export const SlideRow = styled.div`
  width: ${(props: ISlideRow) => `${props.slideCount * 100}%`};
  height: 100%;
  display: flex;
  position: relative;
  transition: left 0.5s cubic-bezier(0.46, 0.01, 0.45, 1.03);
`;
SlideRow.displayName = 'SlideRow';

export const SlideControls = styled.div`
  width: calc(100% - 8px);
  height: ${theme.spacing(4)};
  position: absolute;
  top: calc(50% - 24px);
  display: ${(props: ISlideControls) => (props.visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: space-between;
  margin: ${theme.spacing(1)} 0 0 0;
  padding-left: 4px;
  padding-right: 4px;
`;
SlideControls.displayName = 'SlideControls';

export const SlideButton = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.0);
  cursor: pointer;
`;

export const PrevButton = styled(ChevronBackCircle)`
  width: 32px;
  height: 32px;
`;
PrevButton.displayName = 'PreviousButton';

export const NextButton = styled(ChevronForwardCircle)`
  width: 32px;
  height: 32px;
`;
NextButton.displayName = 'NextButton';

export const ImagePipRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 10;
  bottom: ${theme.spacing(2)};
  margin: 0 auto;
`;

export const ImagePip = styled.button`
  width: 8px;
  height: 8px;
  margin: 0 4px;
  padding: 0;
  border-radius: 4px;
  background-color: ${theme.palette.neutral.white};
  filter: drop-shadow(0px 1px 0px rgba(0, 0, 0, 0.3));
  opacity: ${(props: IImagePip) => (props.active ? 0.8 : 0.3)};
  border: none;
  cursor: pointer;
`;
ImagePip.displayName = 'ImagePip';
