import styled from 'styled-components';
import { Body } from 'shared/typography';
import { FlexColumn } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import { IFeatureImage } from './types';
import { rgba } from '../../../shared/palette/utils';

export const FeatureRow = styled(FlexColumn)`
  max-width: ${theme.maxWidth};
  
  justify: space-between;
  
  margin: 0 auto;
  
  @media ${theme.device.laptopM} {
    flex-direction: row;
    align-items: flex-start;
    margin-top: ${theme.spacing(10)};
  }
`;

export const FeatureWrapper = styled(FlexColumn)`
  width: calc(100vw - ${theme.spacing(8)});
  padding: 0 32px 100px 32px;
  
  @media ${theme.device.tablet} {
    flex-direction: row;
  }
  @media ${theme.device.laptopM} {
    width: 374px;
    flex-direction: column;
  }
`;

export const FeatureImage = styled.div`
  width: ${theme.spacing(32)};
  height: ${theme.spacing(32)};
  
  border-radius: ${theme.spacing(16)};
  flex: none;
  
  position: relative;
  background-image: ${(props:IFeatureImage) => `url(${props.image})`};
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 4px ${rgba(theme.palette.neutral.black, 0.3)};
  
  @media ${theme.device.laptopL} {
    flex-direction: column;
  }
  
  &::before {
    content: '';
    width: ${theme.spacing(35)};
    height: ${theme.spacing(35)};
    
    position: absolute;
    top: ${theme.spacing(-1.5)};
    left: ${theme.spacing(-1.5)};
    z-index: -1;
    
    background-color: ${rgba(theme.palette.neutral.white, 0.1)};
    border-radius: ${theme.spacing(18)};
  }
`;
FeatureImage.displayName = 'FeatureImage';

export const FeatureDetailsWrapper = styled(FlexColumn)`
  padding: 0 ${theme.spacing(3)};
  margin-top: ${theme.spacing(3)};
  
  @media ${theme.device.tablet} {
    width: 50%;
    margin-top: 0;
  }
  
  @media ${theme.device.laptopM} {
    width: 100%;
    padding: 0;
    margin-top: ${theme.spacing(4)};
  }
`;

// see shared/pageElements/styledComponents for FeatureTitle definition

export const FeatureBody = styled(Body)`
  color: ${theme.palette.neutral.white};
  text-align: center;
  
  @media ${theme.device.tablet} { 
    text-align: left;
  }
  
  @media ${theme.device.laptopM} {
    text-align: center;
    padding: 0 ${theme.spacing(2)};
  }
`;
