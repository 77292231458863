import styled from 'styled-components';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { Body } from 'shared/typography';
import { LinkComponent } from 'shared/buttons/styledComponents';
import { rgba } from 'shared/palette/utils';
import theme from 'shared/theme';
import { Close, LogoInstagram } from '@styled-icons/ionicons-solid';
import {
  IHamburgerBar, IMobileMenuWrapper, INavBarBackground, INavBarHeight, INavOuterWrapper,
} from './types';

export const NavLinkWrapper = styled(FlexRow)`
  height: ${theme.spacing(10)};
  background-color: ${theme.palette.neutral.transparent};
  
  
  display: none;
  @media ${theme.device.laptop} {
    display: flex;
  }
`;
NavLinkWrapper.displayName = 'NavLinkWrapper';

export const NavBarBackground = styled.div`
  width: 100vw;
  height: ${theme.spacing(11)};

  background-color: ${rgba(theme.palette.neutral.black, 0.8)};
  backdrop-filter: blur(8px);
  
  display: none;
   
  position: fixed;
  z-index: 10000;  
  overflow: hidden;
  
  transition: all 0.3s ${theme.easing.transitionTiming}; 
  
  @media ${theme.device.laptop} {
    display: unset;
    top: 0;
    height: auto;
    background-color: ${(props:INavBarBackground) => rgba(theme.palette.neutral.black, props.blurBackground ? 0.8 : 0)};
    backdrop-filter: ${(props: INavBarBackground) => `blur(${props.blurBackground ? '8px' : '0px'})`};
  }
`;
NavBarBackground.displayName = 'NavBarBackground';

export const NavBarOuterWrapper = styled(FlexRow)`
  width: calc(100% - 48px);
  max-width: ${theme.maxWidth};
  height: ${theme.spacing(10)};
  
  position: relative;
  top: 0;
  
  justify-content: 'center';
  
  padding-top: ${theme.spacing(1)};
  margin: 0 auto;
  
  transition: all 0.3s ${theme.easing.transitionTiming};
  
  @media ${theme.device.laptop} {
    height: ${(props:INavBarHeight) => (props.shrinkHeight ? theme.spacing(8) : theme.spacing(15))};
    min-width: 912px;
    padding-top: 0;
  }
  
  @media ${theme.device.laptopL} {
    max-width: ${theme.deviceSizes.laptopL};
    min-width: unset;
  }
`;
NavBarOuterWrapper.displayName = 'NavBarOuterWrapper';

export const NavBarInnerWrapper = styled(FlexRow)`
  width: 100%;
`;
NavBarInnerWrapper.displayName = 'NavBarInnerWrapper';

export const NavButtonComponent = styled(LinkComponent)`
  height: ${theme.spacing(3.5)};
  
  margin-bottom: ${theme.spacing(3.5)};
  &::after {
    height: ${(props: INavBarHeight) => (props.shrinkHeight ? '1px' : '2px')};
    bottom: ${theme.spacing(-1.25)};
    transition: all 0.3s ${theme.easing.transitionTiming};
  }
  
  @media ${theme.device.laptop} {
    margin-bottom: 0;
    &::after {
      bottom: ${(props: INavBarHeight) => (props.shrinkHeight ? theme.spacing(-0.125) : theme.spacing(-0.25))};
    }
  }
  
`;
NavButtonComponent.displayName = 'NavButton';

export const NavLogo = styled(LinkComponent)`
  width: 132px;
  height: auto;

  display: none;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  background-color: ${theme.palette.neutral.transparent};
  border: 0;
  cursor: pointer;

  padding-left: 0;

  & img {
    flex-shrink: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
  }
  
  @media ${theme.device.laptop} {
    display: flex;
    width: ${(props:INavBarHeight) => (props.shrinkHeight ? '148px' : '200px')};
    transition: all 0.3s ${theme.easing.transitionTiming};
  }
`;
NavLogo.displayName = 'NavLogo';

export const InstaNavButton = styled.a`
  width: ${theme.spacing(5)};
  height: ${theme.spacing(5)};
  
  color: ${theme.palette.neutral.white};
  text-decoration: none;
  
  margin: ${theme.spacing(1)} 0 0 0;
  padding-left: ${theme.spacing(2)};
  
  @media ${theme.device.laptop} {
    height: ${theme.spacing(3.5)};
    
    display: flex;
    justify-content: flex-end;
    
    margin: 0;
    padding-left: 0;
    min-width: ${theme.spacing(5)};
  }
`;

export const InstagramButton = styled(LogoInstagram)`
  color: ${theme.palette.neutral.white}
  width: ${theme.spacing(4)};  
  height: ${theme.spacing(4)};
  
  @media ${theme.device.laptop} {
   width: ${theme.spacing(3)};  
    height: ${theme.spacing(3)}; 
  }
`;
InstagramButton.displayName = 'InstagramButton';

// MOBILE
export const NavButtonText = styled(Body)`
  @media ${theme.device.mobileS} {
    font-size: ${theme.typography.h4.size.large};
  }
  
  @media ${theme.device.laptop} {
    font-size: ${theme.typography.body.size.large};
  }
  margin: 0;
`;
NavButtonText.displayName = 'NavButtonText';

export const NavMobileMenu = styled(FlexColumn)`
  width: calc(100vw - ${theme.spacing(1.5)});
  height: 100%;
  
  background-color: transparent;
  
  display: ${(props: IHamburgerBar) => (props.menuOpen ? 'flex' : 'none')};
  
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  
  margin-top: ${(props: IHamburgerBar) => (props.menuOpen ? theme.spacing(5) : 0)};
  padding-left: ${theme.spacing(2.5)};
  
  @media ${theme.device.laptop} {
    display: none;
  }
  
  transition: width 0.3s ${theme.easing.transitionTiming}, margin-top 0.3s ${theme.easing.transitionTiming};
`;
NavMobileMenu.displayName = 'NavMobileMenu';

export const MobileNavBackground = styled(FlexColumn)`
  width: 100vw;
  height: ${(props: INavOuterWrapper) => (props.menuOpen ? '100vh' : theme.spacing(11))};
  
  position: fixed;
  top: ${(props: INavOuterWrapper) => (props.showNav ? theme.spacing(-11) : theme.spacing(0))};
  left: 0;
  z-index: 10000;
  overflow: hidden;
  
  justify-content: flex-start;
  align-items: flex-start;
  
  background-color: ${(props: INavOuterWrapper) => (props.menuOpen
    ? rgba(theme.palette.neutral.black, 0.8) : 'transparent')};
  backdrop-filter: ${(props: INavOuterWrapper) => (props.menuOpen ? 'blur(8px)' : 'unset')};
  
  transition: all 0.3s ${theme.easing.transitionTiming};
  
  @media ${theme.device.laptop} {
   display: none;
  }
`;
MobileNavBackground.displayName = 'MobileNavBackground';

export const MobileNavWrapper = styled(FlexColumn)`
  width: ${theme.spacing(8)};
  height: ${theme.spacing(8)};
  border-radius: ${theme.spacing(6)};
 
  position: relative;
  top: 20px;
  left: 16px;
  
  background-color: transparent;
 
  transition: all 0.3s ${theme.easing.transitionTiming};
  
  @media ${theme.device.laptop} {
   display: none;
  }
`;
MobileNavWrapper.displayName = 'MobileNavWrapper';

export const MobileHamburgerWrapper = styled.div`
  width: ${theme.spacing(8)};
  height: ${theme.spacing(8)};
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  cursor: pointer;
  border: 0;
  border-radius: ${theme.spacing(4)};
  box-shadow: ${(props: IMobileMenuWrapper) => `1px 1px 2px 
    ${rgba(theme.palette.neutral.black, props.menuOpen ? 0 : 0.8)}, -1px -1px 2px 
    ${rgba(theme.palette.neutral.black, props.menuOpen ? 0 : 0.2)}`};
  background-color: ${(props: IMobileMenuWrapper) => (props.menuOpen
    ? 'transparent' : rgba(theme.palette.neutral.black, 0.8))};
  backdrop-filter: ${(props: IMobileMenuWrapper) => (props.menuOpen ? 'unset' : 'blur(8px)')};
  
  
  padding: 0;
  
  &:focus {
    outline: none;
  }
  
  @media ${theme.device.laptop} {
    display: none;
  }
 `;
MobileHamburgerWrapper.displayName = 'MobileHamburgerWrapper';

export const HamburgerBar = styled.div`
  width: ${(props: IHamburgerBar) => (props.menuOpen ? theme.spacing(3.5) : theme.spacing(3))};
  height: ${theme.spacing(0.5)};
  border-radius: ${theme.spacing(0.25)};
  
  position: relative;
  left: ${(props: IHamburgerBar) => (props.menuOpen ? theme.spacing(1.25) : theme.spacing(1.5))};  
  
  background-color: ${theme.palette.neutral.white};
  transform: ${(props: IHamburgerBar) => (props.menuOpen ? 'rotate(45deg)' : 'rotate(0deg)')};
  bottom: ${(props: IHamburgerBar) => (props.menuOpen ? theme.spacing(-0.125) : 0)};
  
  margin-bottom: 2px;
  
  &:first-child {
   bottom: ${(props: IHamburgerBar) => (props.menuOpen ? theme.spacing(-0.875) : 0)};
  }
  &:last-child {
    width: ${(props: IHamburgerBar) => (props.menuOpen ? theme.spacing(3.5) : theme.spacing(2))};
    transform: ${(props: IHamburgerBar) => (props.menuOpen ? 'rotate(-45deg)' : 'rotate(0deg)')};
    bottom: ${(props: IHamburgerBar) => (props.menuOpen ? theme.spacing(0.625) : 0)};
  }
  
  transition: transform 0.3s ${theme.easing.transitionTiming}, opacity 0.2s ${theme.easing.transitionTiming};
`;
HamburgerBar.displayName = 'HamburgerBar';

export const WLogo = styled.img`
 width: ${theme.spacing(5.5)};
 height: ${theme.spacing(5.5)};

 display: ${(props: IHamburgerBar) => (props.menuOpen ? 'none' : 'block')};
 opacity: ${(props: IHamburgerBar) => (props.menuOpen ? 0 : 1)};
 transition: opacity 0.3s ${theme.easing.transitionTiming};
`;
WLogo.displayName = 'WLogo';

export const MenuClose = styled(Close)`
 width: ${theme.spacing(5.5)};
 height: ${theme.spacing(5.5)};
 
 display: ${(props: IHamburgerBar) => (props.menuOpen ? 'block' : 'none')};
 
 fill: ${theme.palette.neutral.white};

 opacity: ${(props: IHamburgerBar) => (props.menuOpen ? 1 : 0)};
 transition: opacity 0.3s ${theme.easing.transitionTiming}; 
`;
MenuClose.displayName = 'MenuClose';
