import React, { useState } from 'react';
import theme from 'shared/theme';
import { InputLabel } from 'shared/pageElements/styledComponents';
import { ITextArea } from './types';
import { InputTextArea } from './styledComponents';

const TextArea = ({
  label, onChange, fieldValue,
}: ITextArea) => {
  const [value, setValue] = useState(fieldValue || '');
  const [focusColor, setFocusColor] = useState(theme.palette.neutral[500]);

  const handlers = {
    blur: (e: React.FocusEvent<HTMLTextAreaElement>) => {
      e.preventDefault();
      setFocusColor(theme.form.strokeColor.inactive);
    },
    change: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setValue(e.target?.value);
      onChange(e.target?.value);
    },
    focus: (e: React.FocusEvent<HTMLTextAreaElement>) => {
      e.preventDefault();
      setFocusColor(theme.form.strokeColor.active);
    },
  };

  return (
    <>
      <InputLabel>{label}</InputLabel>
      <InputTextArea
        focusColor={focusColor}
        onBlur={handlers.blur}
        onChange={handlers.change}
        onFocus={handlers.focus}
        value={value}
        wrap="soft"
      />
    </>
  );
};


export default TextArea;
