import React from 'react';
import {
  HeroInnerContainer, HeroHeader, HeroBodyWrapper, HeroBody,
} from '../Hero/styledComponents';
import { HeroFullBleedContainer, FullBleedImage } from './styledComponents';
import { IHeroFullBleed } from './types';

const HeroFullBleed = ({
  height, compact = false, backgroundImage, title, body,
}: IHeroFullBleed) => {
  const h = height || (compact ? '35vh' : undefined);
  return (
    <HeroFullBleedContainer height={h} justify="center">
      <HeroInnerContainer>
        {title
        && <HeroHeader>{title}</HeroHeader>}
        {
          body
          && <HeroBodyWrapper><HeroBody>{body}</HeroBody></HeroBodyWrapper>
        }
      </HeroInnerContainer>
      <FullBleedImage backgroundImage={backgroundImage || ''} />
    </HeroFullBleedContainer>
  );
};

export default HeroFullBleed;
