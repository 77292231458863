import { IContentData } from 'shared/types';
import { ISitePageDef } from './types';
import { IUIState } from './UIState';

const shortid = require('shortid');

class ConfigState {
  apiRoot: string;

  storageRoot: string;

  pages: ISitePageDef;

  constructor() {
    this.apiRoot = 'http://192.168.1.2:5000/api';
    this.storageRoot = 'content';
    this.pages = {
      home: {
        id: shortid.generate(),
        label: 'Home',
        href: '/',
        firebaseRef: 'home',
      },
      services: {
        id: shortid.generate(),
        label: 'Services',
        href: '/services',
        firebaseRef: 'services',
      },
      capabilities: {
        id: shortid.generate(),
        label: 'Product',
        href: '/product',
        firebaseRef: 'product',
      },
      about: {
        id: shortid.generate(),
        label: 'About us',
        href: '/about',
        firebaseRef: 'about',
      },
      // faq: {
      //   id: shortid.generate(),
      //   label: 'FAQ',
      //   href: '/faq',
      //   firebaseRef: 'faq',
      // },
    };
  }
}

export default ConfigState;
