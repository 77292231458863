import styled from 'styled-components';
import theme from 'shared/theme';
import { IEmployeeImage, IImageSlide } from './types';
import { rgba } from '../../../shared/palette/utils';

export const ImageSlide = styled.div`
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
  
  background-image: url(${(props: IImageSlide) => props.imageUrl});
  background-size: ${(props: IImageSlide) => props.backgroundSize || 'cover'};
  background-repeat: no-repeat;
  background-position: center;
`;
ImageSlide.displayName = 'ImageSlide';

export const ImageSlideLabel = styled.p`
    width: auto;
    max-width: calc(100% - ${theme.spacing(6)});
    height: auto;
    position: relative;
    top: ${theme.spacing(3)};
    
    font-size: ${theme.typography.caption.size.medium};
    color: ${theme.palette.neutral.white};
    background-color: ${rgba(theme.palette.neutral.black, 0.3)};
    backdrop-filter: blur(4px);
    border-radius: 8px;
    
    margin: 0 auto;
    padding: ${theme.spacing(0.5)} ${theme.spacing(1.5)};
    
    @media ${theme.device.laptop} {
      width: auto;
      position: absolute;
      top: ${theme.spacing(3)};
      right: ${theme.spacing(3)};
      
      margin: unset;
`;

export const EmployeeSlide = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;
EmployeeSlide.displayName = 'EmployeeSlide';

export const EmployeeImage = styled.div`
  width: 50%;
  height: 100%;
  background-image: url(${(props: IEmployeeImage) => props.imageUrl});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;
EmployeeImage.displayName = 'EmployeeImage';

export const EmployeeDetails = styled.div`
  width: calc(50% - ${theme.spacing(4)});
  height: calc(100% - ${theme.spacing(4)});
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: ${theme.spacing(2)};
`;
EmployeeDetails.displayName = 'EmployeeDetails';

export const EmployeeName = styled.h2`
  margin: 0;
`;
EmployeeName.displayName = 'EmployeeName';

export const EmployeeJobTitle = styled.p`
  margin-top: ${theme.spacing(0.5)};
  margin-bottom: 0;
`;
EmployeeJobTitle.displayName = 'EmployeeJobTitle';

export const Slide = styled.div`
  width: 100%;
  height: 100%;
  display: inline-block;
`;
Slide.displayName = 'Slide';
