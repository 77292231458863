import React, { useState } from 'react';
import { InputLabel } from 'shared/pageElements/styledComponents';
import { IRadioButtonSet } from './types';
import { RadioButton, RadioButtonGroup } from './styledComponents';
import theme from '../../../shared/theme';

const RadioButtonSet = ({ label, initialValue, options, onChange }: IRadioButtonSet) => {
  const [current, setCurrent] = useState(initialValue);

  const handlers = {
    click: (e: React.MouseEvent<HTMLButtonElement>) => {
      setCurrent(parseInt(e.currentTarget?.value, 10));
      onChange(e);
    },
  };

  return (
    <>
      <InputLabel>{label}</InputLabel>
      <RadioButtonGroup>
        {options.map((option, index) => {
          const color = index === current ? theme.palette.primary.hue : theme.palette.neutral[100];
          const labelColor = index === current ? theme.palette.neutral.white : theme.palette.neutral[800];

          return (
            <RadioButton
              id={option.id}
              key={option.id}
              value={index}
              focusColor={color}
              labelColor={labelColor}
              onClick={handlers.click}
            >
              {option.label}
            </RadioButton>
          );
        })}
      </RadioButtonGroup>
    </>
  );
};

export default RadioButtonSet;
