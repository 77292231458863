import React from 'react';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/database';
import 'firebase/analytics';
import 'firebase/functions';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

firebase.initializeApp(config);

const firebaseContext = React.createContext({
  firebase,
  analytics: firebase.analytics(),
  database: firebase.database(),
  functions: firebase.functions(),
  storage: firebase.storage(),
});

const useFirebase = () => React.useContext(firebaseContext);

export default useFirebase;
