import styled from 'styled-components';
import theme from 'shared/theme';
import { IImageComponent } from './types';

export const ImageWrapper = styled.div`
  width: calc(100% - ${theme.spacing(6)});
  height: auto;
  
  align-self: center;
  
  
  @media ${theme.device.tablet} {
    width: calc(100% - ${theme.spacing(12)});
  }
  
  @media ${theme.device.laptop} {
    max-width: ${theme.maxWidth};
  }
`;

export const ImageComponent = styled.img`
  width: 100%;
  
  margin-bottom: ${theme.spacing(8)};
  
  opacity: ${(props: IImageComponent) => (!props.src || props.src.length > 0 ? 1 : 0)};
  transition: opacity 0.5s ease-in;
`;
