import RadioButtonSet from 'components/formFields/RadioButtonSet';
import React, { useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import TextArea from 'components/formFields/TextArea';
import TextInput from 'components/formFields/TextInput';
import theme from 'shared/theme';
import useAppState from 'state';
import { FormSubmitCta } from 'components/buttons/SectionCta';
import { InputFieldWrapper } from 'shared/pageElements/styledComponents';
import { formValidators } from 'shared/text';
import { observer } from 'mobx-react';
import useFirebase from 'vendor/Firebase';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  SectionBody, SectionBodyWrapper, SectionHeader,
} from 'components/pageSections/TopicSection/styledComponents';
import {
  FieldRequirementText,
  FormWrapper,
  ResultScreen,
  ResultTextWrapper,
  RedirectButton,
  RecaptchaCaption,
  CheckboxWrapper,
  Checkbox,
  CheckboxText, CheckboxFill,
} from './styledComponents';
import { IContactForm } from './types';
import { scrollToHash } from '../../../shared/scroll';

const shortid = require('shortid');

const ContactForm = observer(({ id, title, bodyText }: IContactForm) => {
  const { contactFormState, uiState } = useAppState();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { functions } = useFirebase();
  const [isSubmitting, setSubmitting] = useState(false);

  const postCompleteData = {
    success: {
      header: 'Thank you for contacting us.',
      body: 'We will take a look at your submission and get back to you soon.',
      action: 'Back to top',
    },
    fail: {
      header: 'Uh-oh, something went wrong.',
      body: 'Please try resubmitting the form. If you still have problems, drop us a line at info@wildwoodmfg.com.',
      action: 'Retry submission',
    },
  };

  const [postCompleteHeader, setPostCompleteHeader] = useState(postCompleteData.success.header);
  const [postCompleteBody, setPostCompleteBody] = useState(postCompleteData.success.body);
  const [postCompleteAction, setPostCompleteAction] = useState(postCompleteData.success.action);
  const [postComplete, setPostComplete] = useState(false);
  const [invalid, setInvalid] = useState(contactFormState.formInvalid);

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSubmitting(true);
    // @ts-ignore
    executeRecaptcha('contact_form').then((token) => {
      const data = {
        recaptchaToken: token,
        email: contactFormState.email.value,
        name: contactFormState.name.value,
        company: contactFormState.company.value,
        websiteUrl: contactFormState.websiteUrl.value,
        bodyQuantity: contactFormState.bodyQuantity.value,
        neckQuantity: contactFormState.neckQuantity.value,
        inProduction: contactFormState.inProduction.value,
        notes: contactFormState.notes.value,
      };
      functions.httpsCallable('api-v1-contact-formHandler')(data)
        .then((result) => {
          console.log(result);
          if (result.data.status === 200 || result.data.status === 204) {
            setPostCompleteAction(postCompleteData.success.action);
            setPostCompleteHeader(postCompleteData.success.header);
            setPostCompleteBody(postCompleteData.success.body);
            setPostComplete(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setSubmitting(false);
          setPostCompleteAction(postCompleteData.fail.action);
          setPostCompleteHeader(postCompleteData.fail.header);
          setPostCompleteBody(postCompleteData.fail.body);
          setPostComplete(true);
        });
    })
      .catch((error) => {
        console.log(error);
        setPostCompleteAction(postCompleteData.fail.action);
        setPostCompleteHeader(postCompleteData.fail.header);
        setPostCompleteBody(postCompleteData.fail.body);
        setPostComplete(true);
      });
  };

  const changeHandlers = {
    email: (value: string, valid: boolean) => {
      contactFormState.email = { value, valid };
      setInvalid(contactFormState.formInvalid);
    },
    name: (value: string, valid: boolean) => {
      contactFormState.name = { value, valid };
      setInvalid(contactFormState.formInvalid);
    },
    company: (value: string, valid: boolean) => {
      contactFormState.company = { value, valid };
      setInvalid(contactFormState.formInvalid);
    },
    websiteUrl: (value: string, valid: boolean) => {
      contactFormState.websiteUrl = { value, valid };
      setInvalid(contactFormState.formInvalid);
    },
    production: (e: React.MouseEvent<HTMLButtonElement>) => {
      const value = parseInt(e.currentTarget?.value, 10);
      contactFormState.inProduction = { value: !!value };
      setInvalid(contactFormState.formInvalid);
    },
    bodyQuantity: (e: React.MouseEvent<HTMLButtonElement>) => {
      const value = parseInt(e.currentTarget?.value, 10);
      contactFormState.bodyQuantity = { value: value * 50 };
      setInvalid(contactFormState.formInvalid);
    },
    neckQuantity: (e: React.MouseEvent<HTMLButtonElement>) => {
      const value = parseInt(e.currentTarget?.value, 10);
      contactFormState.neckQuantity = { value: value * 50 };
      console.log('foo');
      setInvalid(contactFormState.formInvalid);
    },
    notes: (value: string) => {
      contactFormState.notes = { value };
      setInvalid(contactFormState.formInvalid);
    },
  };

  const updateConsent = () => {
    contactFormState.consent.value = !contactFormState.consent.value;
    setInvalid(contactFormState.formInvalid);
  };

  const onReloadClick = () => {
    if (postCompleteAction === postCompleteData.success.action) {
      window.location.hash = '';
      window.location.pathname = '/about';
      scrollToHash('');
    } else {
      setPostComplete(false);
    }
  };

  return (
    <>
      { postComplete
          && (
          <ResultScreen>
            <ResultTextWrapper>
              <h1>{postCompleteHeader}</h1>
              <p>{postCompleteBody}</p>
              <RedirectButton to="/about" onClick={onReloadClick}>Reload page</RedirectButton>
            </ResultTextWrapper>
          </ResultScreen>
          )}
      <ScrollAnimation animateIn={uiState.sectionAnimation}>
        <FormWrapper id={id} align="flex-start" justify="flex-start">
          <SectionHeader color={theme.palette.neutral.black}>{title}</SectionHeader>
          {
                bodyText.split('\\n').map((text) => (
                  <SectionBodyWrapper key={shortid.generate()}>
                    <SectionBody color={theme.palette.neutral.black}>{text}</SectionBody>
                  </SectionBodyWrapper>
                ))
              }
          <FieldRequirementText>*Required field</FieldRequirementText>
          <InputFieldWrapper>
            <TextInput
              label="Email*"
              errorText="Please enter a valid email address"
              onChange={changeHandlers.email}
              validator={formValidators.email}
            />
          </InputFieldWrapper>
          <InputFieldWrapper>
            <TextInput
              label="Name*"
              errorText="Please enter your name"
              onChange={changeHandlers.name}
              validator={formValidators.text}
            />
          </InputFieldWrapper>
          <InputFieldWrapper>
            <TextInput
              label="Company*"
              errorText="Please enter your company's name"
              onChange={changeHandlers.company}
              validator={formValidators.text}
            />
          </InputFieldWrapper>
          <InputFieldWrapper>
            <TextInput
              label="Website*"
              errorText="Please enter your website address"
              onChange={changeHandlers.websiteUrl}
              validator={formValidators.text}
            />
          </InputFieldWrapper>
          <InputFieldWrapper
            align="flex-start"
            height={theme.spacing(14)}
            style={{ marginTop: theme.spacing(1.5) }}
          >
            <RadioButtonSet
              initialValue={null}
              label="Are you currently producing guitars?*"
              options={[
                {
                  id: shortid.generate(),
                  label: 'No',
                },
                {
                  id: shortid.generate(),
                  label: 'Yes',
                },
              ]}
              onChange={changeHandlers.production}
            />
          </InputFieldWrapper>
          <InputFieldWrapper
            align="flex-start"
            height={theme.spacing(14)}
            style={{ marginTop: theme.spacing(1.5) }}
          >
            <RadioButtonSet
              initialValue={null}
              label="Approx. annual body count needed*"
              options={[
                {
                  id: shortid.generate(),
                  label: '< 50',
                },
                {
                  id: shortid.generate(),
                  label: '50',
                },
                {
                  id: shortid.generate(),
                  label: '100',
                },
                {
                  id: shortid.generate(),
                  label: '150',
                },
                {
                  id: shortid.generate(),
                  label: '> 200',
                },
              ]}
              onChange={changeHandlers.bodyQuantity}
            />
          </InputFieldWrapper>
          <InputFieldWrapper
            align="flex-start"
            height={theme.spacing(14)}
            style={{ marginTop: theme.spacing(1.5) }}
          >
            <RadioButtonSet
              initialValue={null}
              label="Approx. annual neck count needed*"
              options={[
                {
                  id: shortid.generate(),
                  label: '< 50',
                },
                {
                  id: shortid.generate(),
                  label: '50',
                },
                {
                  id: shortid.generate(),
                  label: '100',
                },
                {
                  id: shortid.generate(),
                  label: '150',
                },
                {
                  id: shortid.generate(),
                  label: '> 200',
                },
              ]}
              onChange={changeHandlers.neckQuantity}
            />
          </InputFieldWrapper>
          <InputFieldWrapper style={{
            height: 'auto',
            minHeight: 224,
            maxHeight: 300,
            marginTop: theme.spacing(3),
          }}
          >
            <TextArea
              label="Tell us a bit about yourself and your business"
              onChange={changeHandlers.notes}
              fieldValue={contactFormState.notes.value}
            />
          </InputFieldWrapper>
          <InputFieldWrapper height="auto">
            <CheckboxWrapper checked={contactFormState.consent.value} onClick={updateConsent}>
              <Checkbox checked={contactFormState.consent.value} onClick={updateConsent}>
                <CheckboxFill checked={contactFormState.consent.value} />
              </Checkbox>
              <CheckboxText>
                I consent to Wildwood Manufacturing storing my submitted
                information for the  purpose of responding to my inquiry.
              </CheckboxText>
            </CheckboxWrapper>
          </InputFieldWrapper>
          <InputFieldWrapper align="flex-start" height="auto">
            <FormSubmitCta
              onClick={onSubmit}
              justify="flex-start"
              color={theme.palette.primary.hue}
              label="Submit"
              disabled={invalid || isSubmitting}
            />
            <RecaptchaCaption>
              This site is protected by reCAPTCHA and the Google
              {' '}
              <a href="https://policies.google.com/privacy">Privacy Policy</a>
              {' '}
              and
              {' '}
              <a href="https://policies.google.com/terms">Terms of Service</a>
              {' '}
              apply.
            </RecaptchaCaption>
          </InputFieldWrapper>
        </FormWrapper>
      </ScrollAnimation>
    </>
  );
});

export default ContactForm;
