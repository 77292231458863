import React from 'react';

import ConfigState from './states/ConfigState';
import ContactFormState from './states/ContactFormState';
import UIState from './states/UIState';
import UserState from './states/UserState';

const storesContext = React.createContext({
  configState: new ConfigState(),
  contactFormState: new ContactFormState(),
  uiState: new UIState(),
  userState: new UserState(),
});

const useAppState = () => React.useContext(storesContext);

export default useAppState;
