import React from 'react';
import useAppState from 'state';
import { observer } from 'mobx-react';
import { IFAQItem } from './types';
import {
  FAQAnswer, FAQAnswerRow, FAQChevron, FAQExpander, FAQQuestion, FAQQuestionRow, FAQWrapper,
} from './styledComponents';
import theme from '../../../shared/theme';

const FAQItem = observer(({
  id, index, question, answer,
}: IFAQItem) => {
  const { uiState } = useAppState();
  const onClick = () => {
    uiState.currentFaqItem = index;
  };

  const expanded = uiState.currentFaqItem === index;

  return (
    <FAQWrapper id={id} onClick={onClick}>
      <FAQQuestionRow>
        <FAQQuestion expanded={expanded}>{question}</FAQQuestion>
        <FAQExpander expanded={expanded}>
          <FAQChevron expanded={expanded} width={theme.spacing(4)} height={theme.spacing(4)} />
        </FAQExpander>
      </FAQQuestionRow>
      <FAQAnswerRow expanded={expanded}>
        <FAQAnswer expanded={expanded}>{answer}</FAQAnswer>
      </FAQAnswerRow>
    </FAQWrapper>
  );
});

export default FAQItem;
