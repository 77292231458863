import React, { useEffect, useState } from 'react';
import useFirebase from 'vendor/Firebase';
import useAppState from 'state';
import { ImageSlide as SlideComponent, ImageSlideLabel } from './styledComponents';
import { IImageSlide } from './types';

const ImageSlide = ({
  id, imageUrl, label, backgroundSize,
}: IImageSlide) => {
  const [image, setImage] = useState('');
  const { configState } = useAppState();
  const { firebase } = useFirebase();

  useEffect(() => {
    firebase.storage().ref(configState.storageRoot).child(imageUrl).getDownloadURL()
      .then((res: string) => {
        setImage(res);
      });
  }, [imageUrl]);
  return (
    <SlideComponent id={id} imageUrl={image} label={label} backgroundSize={backgroundSize}>
      { label
        && <ImageSlideLabel>{label}</ImageSlideLabel>}
    </SlideComponent>
  );
};

export default ImageSlide;
