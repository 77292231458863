import styled from 'styled-components';
import { Body, H2 } from 'shared/typography';
import { FlexRow } from 'shared/containers/FlexContainer/styledComponents';
import theme from 'shared/theme';
import { rgba } from 'shared/palette/utils';
import { IFeaturePointImage, IFeaturePointText, IFeatureSeparator } from './types';

export const FeaturePointOuterWrapper = styled.div`
  width: 100vw;
  height: auto;
  
  position: relative;
  
  padding: ${theme.spacing(8)} 0 0 0;
  
  @media ${theme.device.mobileL} {
      calc(100vw - ${theme.spacing(6)});    
  }
  
  @media ${theme.device.laptop} {
    padding: ${theme.spacing(16)} 0;
  }
`;

export const FeaturePointWrapper = styled(FlexRow)`
  // width: calc(100vw - ${theme.spacing(6)});
  width: 100vw;
  @media ${theme.device.mobileS} and (hover: none) {
    height: auto;
  }
  
  @media ${theme.device.mobileS} and (hover: hover) {
    height: auto;
  }

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  position: relative;
  margin: 0 auto;
  
  @media ${theme.device.tablet} {
      height: 800px;
  }
  
  @media ${theme.device.laptop} {
    width: calc(100vw - ${theme.spacing(6)});
    max-width: 1280px;
    height: 600px;
    
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  
  @media ${theme.device.laptopL} {
    max-width: 1440px;
  }
`;

export const FeaturePointTextWrapper = styled.div`
  width: calc(100% - ${theme.spacing(6)});
  height: auto;

  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  justify-content: center;

  background-color: ${rgba(theme.palette.neutral.black, 0.9)};
  backdrop-filter: blur(${theme.spacing(0.5)});
  border-radius: ${theme.spacing(1)};

  padding: 48px 0;
  
  @media ${theme.device.laptop} {
    width: 40%;
    align-self: unset;
    box-shadow: ${(props: IFeaturePointText) => `${props.reverse ? '8px' : '-8px'} 8px 0px 1px #0F0F0F,
      ${props.reverse ? '-1px' : '1px'} -1px 4px ${rgba(theme.palette.neutral.black, 0.2)}`};
    position: absolute;
    z-index: 2;
    left: ${(props: IFeaturePointText) => (props.reverse ? 'unset' : theme.spacing(0))};
    right: ${(props: IFeaturePointText) => (props.reverse ? theme.spacing(0) : 'unset')};
    
    padding: ${theme.spacing(6)};
  }
  
  @media ${theme.device.laptopL} {
    width: 556px;
  }
`;

export const FeaturePointSeparator = styled.div`
  width: 100vw;
  height: ${theme.spacing(16)};
  position: relative;

  &::before {
    content: '';
    width: ${theme.spacing(6)};
    height: ${theme.spacing(0.5)};
    
    display: ${(props: IFeatureSeparator) => (props.noSeparator ? 'none' : 'block')};
    
    position: absolute;
    bottom: 0;
    left: calc(50% - ${theme.spacing(3)});
    border-radius: ${theme.spacing(0.25)};
    
    background-color: ${rgba(theme.palette.neutral.white, 0.2)};
  }
  @media ${theme.device.laptop} {
    display: none;
  }
`;

export const FeaturePointHeader = styled(H2)`
  color: white;
  margin: 0 0 ${theme.spacing(3)} 0;
  line-height: 96%;
`;

export const FeaturePointBody = styled(Body)`
  color: white;
  margin: 0 0 ${theme.spacing(2)} 0;
`;

export const FeaturePointImage = styled.div`
  width: 100%;
  height: 244px;
  max-width: 100vw;

  z-index: 1;

  background-image: ${(props: IFeaturePointImage) => `url(${props.src})`};
  background-size: cover;
  background-position: 80% center;
  
  @media ${theme.device.laptop} {
    width: 60%;
    height: 100%;
      
    border-radius: ${theme.spacing(1.5)};
    
    position: absolute;
    right: ${(props: IFeaturePointImage) => (props.reverse ? 'unset' : theme.spacing(0))};
    left: ${(props: IFeaturePointImage) => (props.reverse ? theme.spacing(0) : 'unset')};
  }
  @media ${theme.device.laptopL} {
    width: 896px;
    background-position: center;
  }
`;
