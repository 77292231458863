import styled from 'styled-components';
import theme from 'shared/theme';
import { ITextInputStyle } from './types';

export const InputField = styled.input`
  width: 100%;
  height: ${theme.spacing(4)};
  
  position: relative;
  z-index: 2;
  
  border: 0;
  border-bottom: ${(props: ITextInputStyle) => `1px solid ${props.focusColor}`};
  border-radius: 0;
  background-color: transparent;
  
  font-family: 'Red Hat Text';
  font-size: ${theme.typography.body.size.small};
  color: ${theme.palette.neutral.black};
  
  display: block;
  padding: ${(props: ITextInputStyle) => `${props.paddingTop} 0 ${theme.spacing(0.5)} 0`};
  
  transition: border-bottom 0.3s ${theme.easing.transitionTiming}, 
    padding-top 0.2s ${theme.easing.transitionTiming};
  
  &:focus {
    outline: none;
    border-bottom: 1px solid ${theme.palette.neutral.black};
  }
  
  @media ${theme.device.laptop} {
    width: ${theme.spacing(76)};
    font-size: ${theme.typography.body.size.large};
  }
`;
