import styled from 'styled-components';
import { FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import { Body, H4 } from 'shared/typography';
import { ChevronDown } from '@styled-icons/ionicons-solid';
import { IFAQWrapperStyle } from './types';

export const FAQWrapper = styled.div`
  width: calc(100vw - ${theme.spacing(6)});
  max-width: ${theme.maxWidth};
  height: auto;
  min-height: 80px;
  
  display: flex;
  flex-direction: column;
  overflow: hidden;
  
  color: ${theme.palette.neutral.white};
  cursor: pointer;
  border-bottom: 1px solid ${theme.palette.neutral[200]};
  &:last-child {
    border-bottom: 0;
  }
  
  margin: 0 auto;
  padding: 0 ${theme.spacing(2)};
  margin-bottom: ${theme.spacing(6)};
  
  &:hover {
    & h4 {
      color: ${theme.palette.neutral.white};
    }
    & svg {
      color: ${theme.palette.neutral.white};
  }
  
  @media ${theme.device.laptop} {
    width: 100%;
  }
`;

export const FAQQuestionRow = styled(FlexRow)`
  width: 100%;
  height: auto;
  justify-content: space-between;
  align-items: flex-start;
`;

export const FAQExpander = styled.div`
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  
  transform: ${(props: IFAQWrapperStyle) => `rotate(${props.expanded ? '-180deg' : '0deg'})`};  
`;

export const FAQChevron = styled(ChevronDown)`
  color: ${(props: IFAQWrapperStyle) => (props.expanded ? theme.palette.neutral.white : theme.palette.neutral[300])};

  transition: color 0.3s ${theme.easing.transitionTiming};
`;

export const FAQQuestion = styled(H4)`
  width: 90%;
  margin: 0;
  
  color: ${(props: IFAQWrapperStyle) => (props.expanded ? theme.palette.neutral.white : theme.palette.neutral[300])};
  
  transition: color 0.3s ${theme.easing.transitionTiming};
`;

export const FAQAnswerRow = styled.div`
  height: auto;
  overflow: hidden;
  margin-bottom: ${(props: IFAQWrapperStyle) => (props.expanded ? theme.spacing(3) : 0)};
  
  transition: margin-bottom 0.3s ease-in-out;
`;

export const FAQAnswer = styled(Body)`
  width: 75%;
  height: auto;
  
  line-height: ${(props: IFAQWrapperStyle) => (props.expanded ? '120%' : '0%')};
  opacity: ${(props: IFAQWrapperStyle) => (props.expanded ? 1 : 0)};
  transform: ${(props: IFAQWrapperStyle) => (props.expanded ? 'scale3d(1, 1, 1)' : 'scale3d(0.99, 0.99, 1)')};
  
  transition: ${(props: IFAQWrapperStyle) => `line-height ${props.expanded ? '0.2s' : '0.2s'} ease-in-out, 
    opacity ${props.expanded ? '0.4s' : '0.1s'} ease-in-out,
    transform 0.3s ease-in-out`};
`;
