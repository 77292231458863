export const validateField = (fieldValue: any, validator: string): boolean => !!fieldValue.match(new RegExp(validator));

export const formValidators = {
  // eslint-disable-next-line no-useless-escape,max-len
  email: '^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$',
  text: '.+',
  // eslint-disable-next-line no-useless-escape
  url: '[-a-zA-Z0-9@:%._+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)',
};

export const toTitleCase = (str: string) => {
  const parts = str.split(' ');

  if (parts.length === 0) return;
  if (parts.length === 1 && parts[0] === '') return;
  return parts.map((part) => `${part[0].toUpperCase()}${part.slice(1)}`).join(' ');
};
