import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { toTitleCase } from 'shared/text';
import {
  HeroContainer, HeroInnerWrapper, HeroInnerContainer, HeroHeader, HeroPageTitle, HeroBodyWrapper, HeroBody,
} from './styledComponents';
import { IHero } from './types';

const Hero = ({
  backgroundImage, title, body, pageTitle,
}: IHero) => (
  <>
    <HeroContainer justify="center" backgroundImage={backgroundImage}>
      <HeroInnerWrapper>
        <HeroInnerContainer>
          <HeroPageTitle>{toTitleCase(pageTitle || '')}</HeroPageTitle>
          {title
        && <HeroHeader>{title}</HeroHeader>}
          {
          body
          && <HeroBodyWrapper><HeroBody>{ReactHtmlParser(body)}</HeroBody></HeroBodyWrapper>
        }
        </HeroInnerContainer>
      </HeroInnerWrapper>
    </HeroContainer>
  </>
);
export default Hero;
