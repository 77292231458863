import React, { useEffect, useState } from 'react';
import useAppState from 'state';
import useFirebase from 'vendor/Firebase';
import { IStaticImage } from './types';
import { ImageComponent, ImageWrapper } from './styledComponents';

const StaticImage = ({
  id, imageUrl, title, style,
}: IStaticImage) => {
  const { configState } = useAppState();
  const { firebase } = useFirebase();
  const [image, setImage] = useState('');

  useEffect(() => {
    firebase.storage().ref(configState.storageRoot).child(imageUrl).getDownloadURL()
      .then((res: string) => {
        setImage(res);
      });
  });
  return (
    <ImageWrapper style={style}>
      <ImageComponent id={id} src={image} alt={title} />
    </ImageWrapper>
  );
};
export default StaticImage;
