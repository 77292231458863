import styled from 'styled-components';
import Container from 'shared/containers/ContentContainer/styledComponents';
import theme from 'shared/theme';
import { IFullBleedImage, IHeroFullBleed } from './types';

export const HeroFullBleedContainer = styled(Container)<IHeroFullBleed>`
  height: ${(props: IHeroFullBleed) => props.height || '60vh'};
  min-height: 400px;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: ${(props:IHeroFullBleed) => props.justify || 'center'};
  position: relative;
`;

export const FullBleedImage = styled.div`
  width: 100vw;
  height: 100vw;
  
  position: absolute;
  z-index: 0;
  top: 0;
  
  &::before {
    width: 100%;
    height: 100%;
    
    content: '';
    
    display: block;
    position: absolute;
    z-index; 0;
    
    opacity: 0.75;
    background-image: url(${(props: IFullBleedImage) => props.backgroundImage});
    background-size: cover;
    background-position-x: center;
    background-repeat: no-repeat; 
  }
  
  @media ${theme.device.laptop} {
    &::before {
      background-position-y: top;
      background-size: 100%;
    }
  }
  
  @media ${theme.device.desktop} {
    &::before {
      background-size: auto;
    }
  }
`;
