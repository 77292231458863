import styled from 'styled-components';
import { Body, H2 } from 'shared/typography';
import { FlexColumn } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import { ITopicSection, ITopicText } from './types';

export const TopicSectionOuterWrapper = styled(FlexColumn)`
  width: calc(100vw - ${theme.spacing(6)}); 
  // height: ${(props: ITopicSection) => (props.lastOfPage ? 'auto' : `calc(100vh - ${theme.spacing(8)})`)};
  height: auto;
  position: relative;
  
  justify-content: flex-start;
  
  margin: 0 auto;
  padding: ${theme.spacing(5)} ${theme.spacing(3)} ${theme.spacing(8)} ${theme.spacing(3)};
  padding-bottom: ${(props: ITopicSection) => (props.lastOfPage ? theme.spacing(3) : theme.spacing(8))};
  
  align-items: center;
  background-color: ${(props: ITopicSection) => (props.invert
    ? theme.palette.neutral.white : theme.palette.neutral.transparent)};
  
  opacity: ${(props: ITopicSection) => (!props.backgroundImage || props.backgroundImage.length > 0 ? 1 : 0)};
  transition: opacity 0.5s ease-in;
    
  &::before {
    width: 100%;
    height: 100%;
    
    content: '';
    
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index; 0;
    
    opacity: ${(props: ITopicSection) => (props.backgroundImage && props.backgroundImage.length > 0 ? 0.8 : 0)};
    background-image: url(${(props: ITopicSection) => props.backgroundImage});
    background-size: cover;
    background-position-y: center;
    background-position-x: 60%;
    background-repeat: no-repeat; 
    
    transition: opacity 0.5s ease-in;
  }
  
  @media ${theme.device.tablet} {
    width: calc(100vw - ${theme.spacing(12)}); 
    height: ${(props: ITopicSection) => (props.lastOfPage ? 'auto' : '50vh')};
    min-height: ${(props: ITopicSection) => (props.backgroundImage
    ? `${theme.spacing(60)}` : `calc(${theme.spacing(40)} - ${theme.spacing(5)})`)};
    padding: ${theme.spacing(5)} ${theme.spacing(6)} 0 ${theme.spacing(6)};
    padding-bottom: unset;
  }
  
  @media ${theme.device.laptop} {
    height: unset;
    min-height: ${theme.spacing(50)};
    
    justify-content: center;
    align-items: center;
    background-position-x: center;
    
    padding-bottom: ${theme.spacing(6)};
    margin: unset;
  }
`;

export const TopicSectionOuterWrapperAutoHeight = styled(TopicSectionOuterWrapper)`
  height: auto;
  padding-bottom: ${theme.spacing(3)};
`;

export const TopicSectionContainer = styled(FlexColumn)`
  width: 100%;
  height: auto;
  
  max-width: ${theme.maxWidth};
  
  position: relative;
  
  align-items: ${(props) => props.align || 'flex-start'};
  justify-content: ${(props) => props.justify || 'center'};
  
  margin: 0 auto;
  padding-bottom: ${theme.spacing(6.5)};
`;

export const SectionHeader = styled(H2)`
  max-width: ${theme.spacing(100)};
  
  z-index: 1;
  
  line-height: 105%;
  color: ${(props: ITopicText) => props.color};
  text-shadow: ${theme.palette.neutral.black};
  
  margin-bottom: ${theme.spacing(0)};
`;

export const SectionBodyWrapper = styled.div`
  max-width: ${theme.spacing(100)};
  
  z-index: 1;
`;
SectionBodyWrapper.displayName = 'SectionBodyWrapper';

export const SectionBody = styled(Body)`
  color: ${(props: ITopicText) => props.color};
  z-index: 1;
`;
SectionBody.displayName = 'SectionBody';
