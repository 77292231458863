import { decorate, observable } from 'mobx';
import theme from 'shared/theme';

export interface IUIState {
  currentFaqItem: number;

  currentPage: string;

  loading: boolean;

  menuBlur: boolean;
  menuBlurThreshold: number;

  mobileMenu: boolean;
  mobileMenuHide: boolean;

  textColor: {
    primary: string;
    inverted: string;
  };

  textShadow: {
    primary: string;
    inverted: string;
  }

  scrollPosition: number;

  sectionAnimation: string;
}

class UIState {
  currentFaqItem: number;

  currentPage: string;

  loading: boolean;

  menuBlur: boolean;

  menuBlurThreshold: number;

  mobileMenu: boolean;

  mobileMenuHide: boolean;

  textColor: {
    primary: string;
    inverted: string;
  };

  textShadow: {
    primary: string;
    inverted: string;
  };

  scrollPosition: number;

  sectionAnimation: string;

  constructor() {
    this.currentFaqItem = 0;
    this.currentPage = '';

    this.loading = false;

    this.menuBlur = false;
    this.menuBlurThreshold = 75; // y value at which menu background should blur (in desktop mode)

    this.mobileMenu = false;
    this.mobileMenuHide = false;

    this.textColor = {
      primary: theme.palette.neutral.white,
      inverted: theme.palette.neutral.black,
    };

    this.textShadow = {
      primary: `1px 1px 1px ${theme.palette.neutral.black}`,
      inverted: `0 0 0 ${theme.palette.neutral.transparent}`,
    };

    this.scrollPosition = 0;

    this.sectionAnimation = 'animateInUp';
  }
}

decorate(UIState, {
  currentPage: observable,
  loading: observable,
  menuBlur: observable,
  mobileMenu: observable,
  mobileMenuHide: observable,
  currentFaqItem: observable,
  scrollPosition: observable,
});

export default UIState;
