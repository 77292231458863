import React, { useEffect, useState } from 'react';
import useAppState from 'state';
import useFirebase from 'vendor/Firebase';
import { observer } from 'mobx-react';
import {
  MobileNavWrapper, MenuClose, MobileHamburgerWrapper, WLogo,
} from './styledComponents';
import { IMobileHamburger } from './types';

const MobileHamburger = observer(({ onClick }: IMobileHamburger) => {
  const { configState, uiState } = useAppState();
  const { firebase } = useFirebase();
  const [logoImage, setLogoImage] = useState('');

  useEffect(() => {
    firebase.storage().ref(configState.storageRoot).child('images/w.svg').getDownloadURL()
      .then((res) => {
        setLogoImage(res);
      });
  });

  return (
    <>
      {
      logoImage !== ''
        && (
        <MobileNavWrapper>
          <MobileHamburgerWrapper menuOpen={uiState.mobileMenu} onClick={onClick}>
            <WLogo menuOpen={uiState.mobileMenu} src={logoImage} alt="Wildwood Manufacturing" />
            <MenuClose menuOpen={uiState.mobileMenu} />
          </MobileHamburgerWrapper>
        </MobileNavWrapper>
        )
    }
    </>
  );
});

export default MobileHamburger;
