import styled from 'styled-components';
import { BREAKPOINTS } from 'shared/grid/constants';
import theme from 'shared/theme';
import { IContentContainerProps } from './types';

const Container = styled.div<IContentContainerProps>`
  width: 100vw;
  height: auto;
  
  background-color: ${theme.palette.neutral.transparent};
  max-width: ${(props) => (props.fixedWidth ? `${BREAKPOINTS.lg}px` : '100vw')};
  margin: ${(props) => (props.fixedWidth ? '0 auto' : '0')};
`;
Container.displayName = 'Container';

export default Container;
