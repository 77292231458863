import React, { useState } from 'react';
import theme from 'shared/theme';
import { validateField } from 'shared/text';
import { FieldValidationError, InputLabel } from 'shared/pageElements/styledComponents';
import { ITextInput } from './types';
import { InputField } from './styledComponents';

const TextInput = ({
  id, label, errorText, onChange, validator, fieldValue, placeholder,
}: ITextInput) => {
  const [value, setValue] = useState(fieldValue || '');
  const [valid, setValid] = useState(true);
  const [error, setError] = useState('');
  const [focusColor, setFocusColor] = useState(theme.form.strokeColor.inactive);
  const [focusPadding, setFocusPadding] = useState(theme.spacing(0));

  const handlers = {
    blur: (e: React.FocusEvent<HTMLInputElement>) => {
      const isValid = validateField(e.target?.value, validator);
      setValid(!!isValid);
      if (isValid) {
        setError('');
        setFocusColor(theme.form.strokeColor.inactive);
      } else {
        const notBlank = e.target?.value.length > 0;
        if (!notBlank) {
          setFocusPadding(theme.spacing(0));
        }

        setError(errorText);
        setFocusColor(theme.palette.error.hue);
      }
      onChange(e.target?.value, isValid);
    },
    change: (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target?.value);
      onChange(e.target?.value, valid);
    },
    focus: (e: React.FocusEvent<HTMLInputElement>) => {
      e.preventDefault();
      setFocusPadding(theme.spacing(0.5));
      setFocusColor(theme.form.strokeColor.active);
      setError('');
    },
  };

  return (
    <>
      <InputLabel>{label}</InputLabel>
      <InputField
        id={id}
        type="text"
        placeholder={placeholder || ''}
        focusColor={focusColor}
        paddingTop={focusPadding}
        onBlur={handlers.blur}
        onChange={handlers.change}
        onFocus={handlers.focus}
        value={value}
      />
      { error.length > 0 && <FieldValidationError>{error}</FieldValidationError> }
    </>
  );
};

export default TextInput;
