import { decorate, observable } from 'mobx';

class UserState {
  loggedIn: boolean;

  uid: string;

  constructor() {
    this.loggedIn = false;
    this.uid = '0';
  }
}

decorate(UserState, {
  loggedIn: observable,
});

export default UserState;
