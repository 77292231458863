import React from 'react';
import { FlexRow as RowComponent, FlexColumn as ColumnComponent } from './styledComponents';
import { IFlexBox } from './types';

export const FlexRow = ({
  id, children, className, justify, align, wrap, reverse, style,
}: IFlexBox) => (
  <RowComponent id={id} className={className} justify={justify} align={align} wrap={wrap} reverse={reverse} style={style}>
    {children}
  </RowComponent>
);

export const FlexColumn = ({
  id, children, className, justify, align, wrap, reverse, style,
}: IFlexBox) => (
  <ColumnComponent id={id} className={className} justify={justify} align={align} wrap={wrap} reverse={reverse} style={style}>
    {children}
  </ColumnComponent>
);
