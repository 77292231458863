import React from 'react';
import useAppState from 'state';
import { INavButton } from './types';
import { NavButtonComponent } from './styledComponents';

const shortid = require('shortid');

const NavButton = ({
  href, id, children,
}: INavButton) => {
  const { uiState } = useAppState();
  const onClick = () => {
    if (uiState.mobileMenu) {
      setTimeout(() => {
        uiState.mobileMenu = false;
      }, 100);
    }
  };
  return (
    <NavButtonComponent id={id || shortid.generate()} exact to={href} onClick={onClick} shrinkHeight={uiState.menuBlur}>
      {children}
    </NavButtonComponent>
  );
};

export default NavButton;
