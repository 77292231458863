import React, { useEffect } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import theme from 'shared/theme';
import {
  BrowserRouter as Router, Route, Switch, useLocation,
} from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import useAppState from 'state';
import { BODY_SIZE } from 'shared/typography/constants';
import ContentWrapper from 'pages/ContentWrapper';
import ContentPage from 'pages/ContentPage';
import useWindowScrollPosition from '@rehooks/window-scroll-position';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

declare let window: any;

const GlobalStyle = createGlobalStyle`
  html {
    font-size: ${BODY_SIZE}px;
  }
`;

const AppSections = () => {
  const location = useLocation();
  const { configState } = useAppState();

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          { Object.keys(configState.pages).map((pageKey) => {
            const page = configState.pages[pageKey];
            return (
              <Route key={page.id} exact path={page.href}>
                <ContentPage
                  name={page.label}
                  href={page.href}
                  firebaseRef={page.firebaseRef}
                />
              </Route>
            );
          })}
        </Switch>
      </AnimatePresence>
    </>
  );
};

const App = () => {
  const { contactFormState, uiState } = useAppState();
  window.uiState = uiState;
  window.contactFormState = contactFormState;
  const scrollPosition = useWindowScrollPosition({ throttle: 500, passive: true });

  useEffect(() => {
    uiState.scrollPosition = scrollPosition.y;
  });

  const AppRouter = (
    <Router>
      <ContentWrapper>
        <AppSections />
      </ContentWrapper>
    </Router>
  );

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LdpobgZAAAAACHfaeiexMbbeuTGrDZX4svkKM-M">
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        {AppRouter}
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  );
};

export default App;
