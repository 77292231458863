import React from 'react';
import ContentContainer from 'shared/containers/ContentContainer';
import NavBar from 'components/navigation/NavBar/NavBar';
import { ISiteContentPage } from './types';

const ContentWrapper = ({ children }: ISiteContentPage) => (
  <ContentContainer>
    <NavBar />
    <>
      {children}
    </>
  </ContentContainer>
);

export default ContentWrapper;
