import React, { useState, useEffect, MouseEvent } from 'react';
import {
  CarouselWrapper,
  SlideRow,
  SlideControls,
  PrevButton,
  NextButton,
  ImagePip,
  ImagePipRow,
  SlideButton,
  CarouselAnimationWrapper,
} from './styledComponents';
import { ICarousel } from './types';

const shortid = require('shortid');

const Carousel = ({
  autoAdvance = false,
  autoAdvanceDelay = 5000,
  animationConfig = { duration: '0.5s', curve: 'cubic-bezier(0.46, 0.01, 0.45, 1.03)' },
  slides = [],
  showControls = true,
  showPips = true,
}: ICarousel) => {
  const [leftPos, setLeftPos] = useState(0);
  const [currentImage, setCurrentImage] = useState(0);
  const minLeft = (slides.length - 1) * -100;
  const nextButtonId = shortid.generate();
  const prevButtonId = shortid.generate();

  let autoAdvanceInterval: any = null;

  const slideLeft = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e) clearInterval(autoAdvanceInterval);
    const newPos = leftPos === 0 ? minLeft : leftPos + 100;
    setLeftPos(newPos);
    setCurrentImage(Math.abs(newPos / 100));
    if (autoAdvance) {
      const nextButton = document.getElementById(nextButtonId);
      if (!nextButton) return;
      autoAdvanceInterval = setInterval(() => nextButton.click(), autoAdvanceDelay);
    }
  };

  const slideRight = () => {
    const newPos = leftPos === minLeft ? 0 : leftPos - 100;
    setLeftPos(newPos);
    setCurrentImage(Math.abs(newPos / 100));
  };

  const onPip = (event: MouseEvent<HTMLButtonElement>) => {
    const pipIndex = parseInt(event.currentTarget.value, 10);
    const newPos = pipIndex * -100;
    setLeftPos(newPos);
    setCurrentImage(pipIndex);
  };

  useEffect(() => {
    if (autoAdvance) {
      setTimeout(() => {
        const nextButton = document.getElementById(nextButtonId);
        if (!nextButton) return;

        autoAdvanceInterval = setInterval(() => nextButton.click(), autoAdvanceDelay);
        console.log(autoAdvanceInterval);
      }, 2000);
    }
  }, []);

  return (
    <CarouselAnimationWrapper animateIn="animateInUp" animateOnce>
      <CarouselWrapper>
        <SlideRow
          style={{
            left: `${leftPos}%`,
            transition: `all ${animationConfig.duration} ${animationConfig.curve}`,
          }}
          slideCount={slides.length}
        >
          {slides}
        </SlideRow>
        { !!showPips && (
          <ImagePipRow>
            { slides.map((slide, index) => (
              <ImagePip
                key={shortid.generate()}
                onClick={onPip}
                active={currentImage === index}
                value={index}
              />
            ))}
          </ImagePipRow>
        )}
        <SlideControls visible={showControls}>
          <SlideButton id={prevButtonId} onClick={slideLeft}>
            <PrevButton />
          </SlideButton>
          <SlideButton id={nextButtonId} onClick={slideRight}>
            <NextButton />
          </SlideButton>
        </SlideControls>
      </CarouselWrapper>
    </CarouselAnimationWrapper>
  );
};

export default Carousel;
