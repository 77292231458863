import React from 'react';
import theme from 'shared/theme';
import useFirebase from 'vendor/Firebase';
import {
  Wrapper, LabelButton, LabelText, Chevron, FormButton,
} from './styledComponents';
import { ISectionCta, IFormSubmitCta } from './types';

export const SectionCta = ({
  label, href, shadow, color,
}: ISectionCta) => {
  const { firebase } = useFirebase();
  const labelShadow = shadow || '';
  const labelColor = color || theme.palette.neutral.white;

  const onClick = () => {
    firebase.analytics().logEvent(
      'cta_clicked', { value: label, to: href },
    );
  };

  return (
    <Wrapper>
      <LabelButton color={labelColor} to={href} onClick={onClick}>
        <LabelText shadow={labelShadow} color={labelColor}>{label}</LabelText>
      </LabelButton>
      <Chevron color={labelColor} shadow={labelShadow} />
    </Wrapper>
  );
};

export const FormSubmitCta = ({
  label, shadow, color, disabled, justify,
  onClick, align, style,
}: IFormSubmitCta) => {
  const { firebase } = useFirebase();
  const labelShadow = shadow || '';
  const labelColor = disabled ? theme.palette.neutral[300] : color || theme.palette.neutral.white;

  const onSubmitClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    firebase.analytics().logEvent('form_submission', { value: label, from: window.location.pathname });

    if (onClick) onClick(e);
  };

  return (
    <Wrapper justify={justify} align={align} disabled={disabled} style={style}>
      <FormButton
        onClick={onSubmitClick}
        color={labelColor}
        type="submit"
        disabled={disabled}
        style={{ cursor: disabled ? 'default' : 'pointer' }}
      >
        <LabelText shadow={labelShadow} color={labelColor}>{label}</LabelText>
      </FormButton>
      <Chevron color={labelColor} shadow={labelShadow} />
    </Wrapper>
  );
};
