import styled from 'styled-components';
import { Caption } from 'shared/typography';
import theme from 'shared/theme';
import { rgba } from 'shared/palette/utils';
import { LinkComponent } from 'shared/buttons/styledComponents';
import { Checkmark } from '@styled-icons/ionicons-solid/Checkmark';
import { FlexColumn, FlexRow } from '../../../shared/containers/FlexContainer';
import { ICheckbox } from './types';

export const FormWrapper = styled(FlexColumn)`
  width: calc(100vw - ${theme.spacing(6)});
  
  padding: 0 ${theme.spacing(3)} ${theme.spacing(5)} ${theme.spacing(3)};
  
  @media ${theme.device.laptop} {
    width: 100vw;
    max-width: ${theme.maxWidth};
    
    padding: ${theme.spacing(10)} 0 ${theme.spacing(5)} 0;  
  }
`;

export const FieldRequirementText = styled(Caption)`
  color: ${theme.palette.neutral.black};
  
  margin-bottom: ${theme.spacing(3)};
  
  @media ${theme.device.laptop} {
    margin-left: 0;
  }
`;

export const ResultScreen = styled(FlexColumn)`
  width: 100vw;
  height: 100vh;
  
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10000;
  
  background-color: ${rgba(theme.palette.neutral.white, 0.6)};
  backdrop-filter: blur(4px);
`;

export const ResultTextWrapper = styled(FlexColumn)`
  width: 80%;
  height: auto;
  
  background-color: ${theme.palette.neutral.white};
  color: ${theme.palette.neutral.black};
  box-shadow: 2px 2px 4px rgba(0,0,0,0.12), -2px -2px 8px rgba(0,0,0,0.03);
  text-align: left;
  
  padding: ${theme.spacing(10)} ${theme.spacing(6)};
  
  @media ${theme.device.laptop} {
    width: 40%;
    align-items: flex-start;
  }
  
  & h1, p {
    margin: 0
  }
`;

export const RedirectButton = styled(LinkComponent)`
  width: auto;
  height; 48px;
  
  padding: ${theme.spacing(2)};
  margin-top: ${theme.spacing(3)};
  
  background-color: ${theme.palette.primary.hue};
  color: ${theme.palette.neutral.white};
`;

export const RecaptchaCaption = styled(Caption)`
  width: 100%;
  
  color: ${theme.palette.neutral[700]};
  font-size: ${theme.spacing(1.5)};
  
  margin-top: ${theme.spacing(1.5)};
  
  & a {
    color: ${theme.palette.neutral[700]};
    &:visited {
      color: ${theme.palette.neutral[700]};
    }
  }
  
  @media ${theme.device.tablet} {
    font-size: ${theme.spacing(1.5)};
    margin-top: ${theme.spacing(1)};
  }
  
  @media ${theme.device.laptop} {
    width: unset;
    font-size: ${theme.spacing(1.5)};
  }
`;

export const CheckboxWrapper = styled(FlexRow)`
  width: calc(100% - ${theme.spacing(4)});
  height: auto;
  
  justify-content: flex-start;
  background-color: ${(props: ICheckbox) => (props.checked
    ? rgba(theme.palette.success.hue, 0.25) : rgba(theme.palette.neutral[100], 0.5))};
  
  border-radius: ${theme.spacing(0.5)};
  padding: ${theme.spacing(1.5)} ${theme.spacing(2)};
  margin: ${theme.spacing(4)} ${theme.spacing(1.5)} ${theme.spacing(5)} 0;
  
  transition: background-color 0.2s ${theme.easing.transitionTiming};
  
  @media ${theme.device.laptop} {
    width: ${theme.spacing(74)};
  }
`;

export const Checkbox = styled.button`
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  background-color: transparent;
  position: relative;
  
  flex-shrink: 0;
  
  border: ${(props: ICheckbox) => `1px solid ${props.checked
    ? theme.palette.success.hue : theme.palette.neutral.black}`};
  border-radius: ${theme.spacing(0.5)};
  background-color: ${(props: ICheckbox) => (props.checked
    ? theme.palette.success.hue : theme.palette.neutral.transparent)};
  cursor: pointer;
  
  transition: background-color 0.2s ${theme.easing.transitionTiming};
  
  &:focus {
    outline: none;
  }
  
  margin-right: ${theme.spacing(1.5)};
  
  @media ${theme.device.laptop} {
    width: ${theme.spacing(4)};
    height: ${theme.spacing(4)};
  }
`;

// export const CheckboxFill = styled.div`
export const CheckboxFill = styled(Checkmark)`
  width: calc(100% - ${theme.spacing(0.5)});
  height: calc(100% - ${theme.spacing(0.5)});
  position: absolute;
  top: ${theme.spacing(0.25)};
  left: ${theme.spacing(0.25)};
  
  color: ${(props: ICheckbox) => (props.checked
    ? theme.palette.neutral.white : theme.palette.neutral.transparent)};
`;

export const CheckboxText = styled(Caption)`
  flex-grow: 5;
  color: ${theme.palette.neutral.black}
`;
