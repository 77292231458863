import styled from 'styled-components';
import theme from 'shared/theme';
import { ITextAreaStyle } from './types';

export const InputTextArea = styled.textarea`
  width: calc(100% - ${theme.spacing(3)} - 2px);
  height: ${theme.spacing(20)};
  
  border: ${(props: ITextAreaStyle) => `1px solid ${props.focusColor}`};
  border-radius: 0;
  
  font-family: 'Red Hat Text';
  font-size: ${theme.typography.body.size.small};
  
  display: block;
  resize: none;
  padding: ${theme.spacing(1.5)};
  margin-top: ${theme.spacing(2)};
  
  transition: border-bottom 0.3s ${theme.easing.transitionTiming}, 
    padding-top 0.2s ${theme.easing.transitionTiming};
  
  &:focus {
    outline: none;
    border-bottom: 1px solid ${theme.palette.neutral.black};
  }
  
  @media ${theme.device.laptop} {
    width: ${theme.spacing(76)};
    height: ${theme.spacing(38)};
    font-size: ${theme.typography.body.size.large};
  }
`;
