import styled from 'styled-components';
import {
  Caption, H3,
} from 'shared/typography';
import { FlexColumn } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import { IInputFieldWrapper } from './types';

export const Logo = styled.button`
  width: 140px;
  height: auto;
  
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  
  background-color: ${theme.palette.neutral.transparent};
  border: 0;
  cursor: pointer;
  
  & img {
    flex-shrink: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
  }
  
  @media ${theme.device.tablet} {
    width: 200px;
  }
`;
Logo.displayName = 'Logo';

export const FeatureTitle = styled(H3)`
  color: ${theme.palette.neutral.white};
  text-align: center;
  margin: 0;
  
  @media ${theme.device.tablet} {
    width: 100%;
    text-align: left;
  }
  @media ${theme.device.laptopM} {
    text-align: center;
    font-size: 36px;
  }
`;

// forms
export const InputLabel = styled(Caption)`
  width: 100%;
  
  color: ${theme.palette.neutral[700]};
  
  margin: 0;
`;

export const InputFieldWrapper = styled(FlexColumn)`
  height: ${(props: IInputFieldWrapper) => props.height || theme.spacing(12)};
  width: calc(100vw - ${theme.spacing(6)});
  
  overflow: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  
  transition: all 0.3s ${theme.easing.transitionTiming};
  
  @media ${theme.device.laptop} {
    width: 50vw;
    margin-left: 0;
  }
`;

export const FieldValidationError = styled(Caption)`
  width: 100%;
  
  color: ${theme.palette.error.hue};
  text-align: left;
  
  margin-top: ${theme.spacing(0.5)};
`;
