import styled from 'styled-components';
import { IFlexBox } from './types';

export const Flex = styled.div<IFlexBox>`
  display: flex;
  justify-content: ${(props) => props.justify || 'center'};
  align-items: ${(props) => props.align || 'center'};
  flex-wrap: ${(props) => (props.wrap ? 'wrap' : 'nowrap')};
  
  position: relative;
  z-index: 10;
`;

export const FlexRow = styled(Flex)`
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
`;

export const FlexColumn = styled(Flex)`
  flex-direction: ${(props) => (props.reverse ? 'column-reverse' : 'column')};
`;
