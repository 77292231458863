import React from 'react';
import Carousel from 'components/gallery/Carousel';
import Employee from 'components/feature/Employee';
import FeatureItem from 'components/feature/FeatureItem';
import FeaturePoint from 'components/pageSections/ProFeaturePoint';
import Hero from 'components/pageSections/Hero';
import HeroFullBleed from 'components/pageSections/HeroFullBleedImage';
import ImageSlide from 'components/gallery/CarouselSlide/ImageSlide';
import chunk from 'lodash.chunk';
import { CarouselRow } from 'components/gallery/Carousel/styledComponents';
import { TopicSection } from 'components/pageSections/TopicSection';
import { ContentType, IContentItem } from 'shared/types';
import { EmployeeRow } from 'components/feature/Employee/styledComponents';
import { FeatureRow } from 'components/feature/FeatureItem/styledComponents';
import { FlexColumn } from 'shared/containers/FlexContainer';
import { IEmployeeDetails } from 'components/feature/Employee/types';
import { IFeatureItem } from 'components/feature/FeatureItem/types';
import { IImageSlide } from 'components/gallery/CarouselSlide/types';
import employeePhotoRowSize from 'shared/layout';
import ContactForm from 'components/pageSections/ContactForm';
import FAQItem from 'components/feature/FAQItem';
import StaticImage from '../../components/gallery/StaticImage';

const shortid = require('shortid');

const pageConstructor = (content: Array<IContentItem>): React.ReactNode => content.map((item: IContentItem) => {
  const { data } = item;

  if (item.type === ContentType.HERO) {
    return (
      <Hero
        backgroundImage={data.backgroundImage || ''}
        body={data.body}
        compact={data.compact}
        id={item.id}
        key={item.id}
        pageTitle={data.pageTitle || ''}
        title={data.title}
      />
    );
  }
  if (item.type === ContentType.HERO_FULL_BLEED) {
    return (
      <HeroFullBleed
        backgroundImage={data.backgroundImage || ''}
        body={data.body}
        compact={data.compact || false}
        id={item.id}
        key={item.id}
        title={data.title}
      />
    );
  }
  if (item.type === ContentType.TOPIC_SECTION) {
    return (
      <TopicSection
        autoHeight={data.autoHeight || false}
        backgroundImage={data.backgroundImage}
        bodyText={data.body || ''}
        cta={data.cta}
        id={data.id || item.id}
        invert={data.invert || false}
        key={item.id}
        lastOfPage={data.lastOfPage || false}
        noAnimation={data.noAnimation || false}
        style={data.style || {}}
        title={data.title || ''}
      />
    );
  }
  if (item.type === ContentType.FEATURE_ROW) {
    return (
      <FeatureRow key={item.id}>
        {data.items?.map((featureItem: IFeatureItem) => (
          <FeatureItem
            backgroundImage={featureItem.backgroundImage}
            body={featureItem.body}
            id={featureItem.id}
            key={featureItem.id}
            title={featureItem.title}
          />
        ))}
      </FeatureRow>
    );
  }
  if (item.type === ContentType.FEATURE_POINT) {
    return (
      <FeaturePoint
        body={data.body || ''}
        id={item.id}
        imageUrl={data.imageUrl || ''}
        key={item.id}
        header={data.title || ''}
        reverse={data.rowDirection === 'row-reverse'}
        noSeparator={data.noSeparator || false}
      />
    );
  }
  if (item.type === ContentType.CAROUSEL) {
    const imageSlides = data.images?.map((slide: IImageSlide) => (
      <ImageSlide
        backgroundSize={slide.backgroundSize}
        id={slide.id}
        imageUrl={slide.imageUrl}
        label={slide.label || ''}
        key={slide.id}
      />
    ));
    return (
      <CarouselRow key={item.id}>
        <Carousel
          autoAdvance={data.autoAdvance || false}
          autoAdvanceDelay={data.autoAdvanceDelay || 5000}
          showControls={data.showControls || false}
          showPips={data.showPips || false}
          slides={imageSlides || []}
        />
      </CarouselRow>
    );
  }
  if (item.type === ContentType.EMPLOYEE_GROUP) {
    return (
      <>
        { chunk(data.employees, employeePhotoRowSize(data.owner || data.manager || false))
          .map((employeeGroup: Array<IEmployeeDetails>) => (
            <EmployeeRow key={`${item.id}-${shortid.generate()}`}>
              { employeeGroup.map((employee) => (
                <Employee
                  firstName={employee.firstName}
                  id={`employee-${employee.id}`}
                  image={employee.image}
                  jobTitle={employee.jobTitle}
                  key={employee.id}
                  lastName={employee.lastName}
                  manager={data.manager || false}
                  owner={data.owner || false}
                />
              ))}
            </EmployeeRow>
          ))}
      </>
    );
  }
  if (item.type === ContentType.FLEX_COLUMN) {
    return (
      <FlexColumn key={item.id} id={item.id} style={data.style} align={data.align}>
        {pageConstructor(data.content || [])}
      </FlexColumn>
    );
  }
  if (item.type === ContentType.CONTACT_FORM) {
    return (
      <ContactForm key={item.id} id={data.id || ''} title={data.title || ''} bodyText={data.body || ''} />
    );
  }
  if (item.type === ContentType.FAQ_SECTION) {
    return (
      <>
        { data.faqItems?.map((faqItem, index) => (
          <FAQItem
            key={faqItem.id}
            answer={faqItem.answer}
            defaultItem={faqItem.defaultItem || false}
            id={faqItem.id}
            index={index}
            question={faqItem.question}
          />
        ))}
      </>
    );
  }
  if (item.type === ContentType.STATIC_IMAGE) {
    return (
      <StaticImage
        id={item.id}
        imageUrl={data.imageUrl || ''}
        key={item.id}
        style={data.style || {}}
        title={data.title || ''}
      />
    );
  }

  return <div key={shortid.generate()} />;
});

export default pageConstructor;
