import React from 'react';
import useAppState from 'state';
import NavButton from 'components/navigation/NavBar/NavButton';
import { observer } from 'mobx-react';
import { observe } from 'mobx';
import useWindowScrollPosition from '@rehooks/window-scroll-position';
import {
  InstagramButton,
  InstaNavButton,
  MobileNavBackground,
  NavBarBackground,
  NavBarInnerWrapper,
  NavBarOuterWrapper,
  NavButtonText,
  NavLinkWrapper,
  NavLogo,
  NavMobileMenu,
} from './styledComponents';
import { INavBar } from './types';
import MobileHamburger from './MobileHamburger';

const NavBar = observer(({ className }: INavBar) => {
  const { configState, uiState } = useAppState();
  uiState.scrollPosition = useWindowScrollPosition({ throttle: 100 }).y;

  const InstaButton = (
    <InstaNavButton key="insta" id="insta" target="_blank" href="https://instagram.com/wildwoodmfg">
      <InstagramButton />
    </InstaNavButton>
  );

  const NavLinks = [
    ...Object.keys(configState.pages).map((pageKey) => {
      const { pages } = configState;
      const page = pages[pageKey];
      return (
        <NavButton key={page.id} id={page.id} href={page.href}>
          <NavButtonText>{page.label}</NavButtonText>
        </NavButton>
      );
    }),
    // ...[InstaButton],
  ];

  const onMenuClick = () => {
    uiState.mobileMenu = !uiState.mobileMenu;
  };

  observe(uiState, 'scrollPosition', (change) => {
    const { newValue } = change;
    const oldValue = change.oldValue || 0;

    uiState.menuBlur = uiState.menuBlurThreshold < newValue;

    if (newValue > oldValue && oldValue >= 0) {
      if (uiState.mobileMenu) onMenuClick();
      uiState.mobileMenuHide = true;
    } else {
      if (oldValue - newValue < 50) return;
      uiState.mobileMenuHide = false;
    }
  });

  return (
    <>
      <NavBarBackground
        showNav={uiState.mobileMenu}
        hideMobileMenu={uiState.mobileMenuHide}
        blurBackground={uiState.menuBlur}
      >
        <NavBarOuterWrapper shrinkHeight={uiState.menuBlur}>
          <NavBarInnerWrapper justify="space-between">
            <NavLogo to="/" title="Wildwood Home" shrinkHeight={uiState.menuBlur}>
              <img alt="Wildwood Logo" src="/assets/global/images/logo.svg" />
            </NavLogo>
            <NavLinkWrapper className={className} justify="flex-start">
              {NavLinks}
            </NavLinkWrapper>
          </NavBarInnerWrapper>
          <NavMobileMenu menuOpen={uiState.mobileMenu}>
            {NavLinks}
          </NavMobileMenu>
        </NavBarOuterWrapper>
      </NavBarBackground>

      <MobileNavBackground showNav={uiState.mobileMenuHide} menuOpen={uiState.mobileMenu}>
        <MobileHamburger onClick={onMenuClick} />
        <NavMobileMenu menuOpen={uiState.mobileMenu}>
          {NavLinks}
        </NavMobileMenu>
      </MobileNavBackground>
    </>
  );
});

export default NavBar;
