import styled from 'styled-components';
import theme from 'shared/theme';
import { IRadioButtonStyle } from './types';

export const RadioButtonGroup = styled.div`
  height: ${(props) => props.theme.spacing(9)};
  
  display: inline-block;
  justify-content: center;
  align-items: center;
  
  border-radius: ${theme.spacing(1)};
  background-color: ${theme.palette.neutral.white};
  
  margin: ${theme.spacing(2)} 0 ${theme.spacing(1)} 0;
  padding: 0 ${theme.spacing(0)};
  
  @media ${theme.device.laptop} {
    margin: ${theme.spacing(3)} 0 0 0;
  }
`;

export const RadioButton = styled.button`
  width: auto;
  min-width: calc((100vw - ${theme.spacing(6)}) / 5);
  height: ${theme.spacing(6)};
  
  background-color: ${(props: IRadioButtonStyle) => props.focusColor};
  color: ${(props: IRadioButtonStyle) => props.labelColor}; 
  border: 0;
  border-left: 1px solid ${theme.palette.neutral.white};
  cursor: pointer;
  
  font-size: 16px;
  
  transition: background-color 0.2s ${theme.easing.transitionTiming};
  
  padding: 0 ${theme.spacing(1)};
  
  &:first-of-type {
    border-top-left-radius: ${theme.spacing(0.5)};
    border-bottom-left-radius: ${theme.spacing(0.5)};
    border-left: 0;
  }
  
  &:last-of-type {
    border-top-right-radius: ${theme.spacing(0.5)};
    border-bottom-right-radius: ${theme.spacing(0.5)};
  }
  
  &:focus {
    outline: none;
  }
  
  @media ${theme.device.tablet} {
    min-width: ${theme.spacing(10)};
  }
  
  @media ${theme.device.laptop} {
    height: ${theme.spacing(6)};
  }
`;
