import React, { useEffect, useState } from 'react';
import { FeatureTitle } from 'shared/pageElements/styledComponents';
import ScrollAnimation from 'react-animate-on-scroll';
import useFirebase from 'vendor/Firebase';
import useAppState from 'state';
import {
  FeatureWrapper, FeatureImage, FeatureBody, FeatureDetailsWrapper,
} from './styledComponents';
import { IFeatureItem } from './types';

const FeatureItem = ({ title, body, backgroundImage }: IFeatureItem) => {
  const [imageUrl, setImageUrl] = useState('');
  const { configState } = useAppState();
  const { firebase } = useFirebase();

  useEffect(() => {
    firebase.storage().ref(configState.storageRoot).child(backgroundImage || '').getDownloadURL()
      .then((res: string) => {
        setImageUrl(res);
      });
  }, [backgroundImage]);

  return (
    <ScrollAnimation animateIn="animateInUp" animateOnce>
      <FeatureWrapper justify="center" align="center">
        <FeatureImage image={imageUrl || ''} />
        <FeatureDetailsWrapper>
          <FeatureTitle>{title}</FeatureTitle>
          <FeatureBody>{body}</FeatureBody>
        </FeatureDetailsWrapper>
      </FeatureWrapper>
    </ScrollAnimation>

  );
};

export default FeatureItem;
