import styled, { StyledComponent } from 'styled-components';
import theme from 'shared/theme';
import { ITypeStyle } from './types';

const typeElements = {
  h1: styled.h1<ITypeStyle>``,
  h2: styled.h2<ITypeStyle>``,
  h3: styled.h3<ITypeStyle>``,
  h4: styled.h4<ITypeStyle>``,
  body: styled.p<ITypeStyle>``,
  caption: styled.p<ITypeStyle>``,
};

const Typography = (variant: string): StyledComponent<any, any> => {
  // @ts-ignore
  const Element = typeElements[variant];
  // @ts-ignore
  const typeStyle = theme.typography[variant];

  return styled(Element)`
    font-family: ${(props) => props.fontFamily || typeStyle.fontFamily};  
    font-size: ${(props) => props.fontSize || typeStyle.size.xSmall};
    font-style: ${(props) => props.variant || 'normal'};
    font-weight: ${(props) => props.fontWeight || typeStyle.fontWeight};
    line-height: ${(props) => props.lineHeight || typeStyle.lineHeight};  
    
    @media ${theme.device.tablet} {
      font-size: ${(props) => props.fontSize || typeStyle.size.small};
    }
    
    @media ${theme.device.laptop} {
      font-size: ${(props) => props.fontSize || typeStyle.size.medium};
    }
    
    @media ${theme.device.laptopL} {
      font-size: ${(props) => props.fontSize || typeStyle.size.large};
    }
  `;
};

const H1 = Typography('h1');
const H2 = Typography('h2');
const H3 = Typography('h3');
const H4 = Typography('h4');
const Body = Typography('body');
const Caption = Typography('caption');

export {
  H1,
  H2,
  H3,
  H4,
  Body,
  Caption,
};
