import React from 'react';
import { FlexRow } from 'shared/containers/FlexContainer';
import { Caption } from 'shared/typography';
import theme from 'shared/theme';
import useAppState from 'state';
import { observer } from 'mobx-react';
import { Logo } from 'shared/pageElements/styledComponents';
import { FooterColumn, FooterTextWrapper } from './styledComponents';

const SiteFooter = observer(() => {
  const { uiState } = useAppState();

  return (
    <>
      {!uiState.loading
    && (
    <FlexRow>
      <FooterColumn justify="flex-start" align-items="center">
        <Logo>
          <img alt="wildwood logo" src="/assets/global/images/logo.svg" />
        </Logo>
        <FooterTextWrapper>
          <Caption>
            ©2020 Wildwood Manufacturing, Inc.
            {window.innerWidth < parseInt(theme.deviceSizes.laptop, 10) && <br />}
            All Rights Reserved.
          </Caption>
        </FooterTextWrapper>
      </FooterColumn>
    </FlexRow>
    )}
    </>
  );
});

export default SiteFooter;
