import scrollIntoView from 'scroll-into-view';

const scrollOptions = {
  time: 2000,
  align: {
    top: 0,
    topOffset: 120,
  },
  ease: (t: number) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
};

const scrollToHash = (hash: string): void => {
  if (hash.length > 0) {
    const element = document.getElementById(hash.slice(1));

    if (element) scrollIntoView(element, scrollOptions);
  } else window.scrollTo(0, 0);
};

export { scrollOptions, scrollToHash };
