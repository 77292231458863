import styled from 'styled-components';
import theme from 'shared/theme';
import { NavLink } from 'react-router-dom';

const ButtonTypeComponent = (Component: any) => (
  Component`
    width: auto;
    height: ${theme.spacing(3)};
    
    background-color: ${theme.palette.neutral.transparent};
    color: ${theme.palette.neutral.white};
    border: 0;
    border-radius: 2px;
    text-decoration: none;
    
    position: relative;
    
    padding: 0 ${theme.spacing(2)};
    
    cursor: pointer;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
    
    &::after {
      width: 0;
      height: 2px;
      
      content: '';
      border-radius: 1px;
      opacity: 0;
      background-color: ${theme.palette.neutral.white};
      
      position: absolute;
      left: ${theme.spacing(2)};
      bottom: ${theme.spacing(-1.5)};
      
      transition: width 0.25s ${theme.easing.transitionTiming}, 
        opacity 0.35s ${theme.easing.transitionTiming}; 
      transitionDelay: initial;
    }

    &:hover {
      &::after {
        width: calc(100% - ${theme.spacing(4)});
        opacity: 1;
      }
    }
    
    &:focus {
      &::after {
        width: calc(100% - ${theme.spacing(4)});
        opacity: 1;
      }
      outline: none;
    }
    
    &.active {
      &::after {
        width: calc(100% - ${theme.spacing(4)});
        opacity: 1; 
      }
    }
    
    @media ${theme.device.laptop} {
      &::after {
        bottom: ${theme.spacing(-0.25)};
      }
    }
  `
);

export const ButtonComponent = ButtonTypeComponent(styled.button);
export const LinkComponent = ButtonTypeComponent(styled(NavLink));
