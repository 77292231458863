import styled from 'styled-components';
import { FlexRow } from 'shared/containers/FlexContainer';
import { ButtonComponent, LinkComponent } from 'shared/buttons/styledComponents';
import { H4 } from 'shared/typography';
import { ChevronForward } from '@styled-icons/ionicons-solid';
import theme from 'shared/theme';
import { ICtaUnderline, ISectionCtaText, ICtaWrapper } from './types';

export const Wrapper = styled(FlexRow)`
  &:hover {
    & svg {
      left: ${(props: ICtaWrapper) => (props.disabled ? '2px' : '4px')};
    }
  }
`;
Wrapper.displayName = 'SectionCtaWrapper';

export const LabelButton = styled(LinkComponent)`
  height: auto;
  
  padding: 0;
  text-decoration: none;
  
  &::after { 
    left: 0;
    bottom: -2px;
    background-color: ${(props: ICtaUnderline) => props.color || theme.palette.neutral.white};
  }
  
  &:hover {
    &::after {
      width: ${(props: ICtaWrapper) => (props.disabled ? '0' : '100%')};
    }
  }
`;

export const FormButton = styled(ButtonComponent)`
  height: auto;
  
  padding: 0;
  text-decoration: none;
  
  &::after { 
    left: 0;
    bottom: -2px;
    background-color: ${(props: ICtaUnderline) => props.color || theme.palette.neutral.white};
  }
  
  &:hover {
    &::after {
      width: ${(props: ICtaWrapper) => (props.disabled ? '0' : '100%')};
    }
  }
`;

export const LabelText = styled(H4)`
  margin: 0;
  
  color: ${(props:ISectionCtaText) => props.color};
  text-shadow: ${(props: ISectionCtaText) => props.shadow};
`;
LabelText.displayName = 'SectionCtaLabelText';

export const Chevron = styled(ChevronForward)`
  width: ${theme.spacing(3.5)};
  height: ${theme.spacing(3.5)};
  
  position: relative;
  left: 2px;
  top: 2px;
  
  transition: left 0.25s ${theme.easing.transitionTiming} 0.1s};
  
  color: ${(props:ISectionCtaText) => props.color || theme.palette.neutral.white};
`;
Chevron.displayName = 'SectionCtaChevron';
