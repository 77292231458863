import React, { useEffect, useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import useAppState from 'state';
import useFirebase from 'vendor/Firebase';
import { IFeaturePointComponent } from './types';
import {
  FeaturePointOuterWrapper,
  FeaturePointWrapper,
  FeaturePointTextWrapper,
  FeaturePointHeader,
  FeaturePointBody,
  FeaturePointImage, FeaturePointSeparator,
} from './styledComponents';

const FeaturePoint = ({
  reverse,
  header,
  body,
  imageUrl,
  noSeparator,
  id,
}: IFeaturePointComponent) => {
  const { configState, uiState } = useAppState();
  const { firebase } = useFirebase();

  const [image, setImage] = useState('');

  useEffect(() => {
    firebase.storage().ref(configState.storageRoot).child(imageUrl || '').getDownloadURL()
      .then((res: string) => {
        setImage(res);
      });
  }, [imageUrl]);

  return (
    <ScrollAnimation animateIn={uiState.sectionAnimation} offset={0} duration={1} animateOnce>
      <FeaturePointOuterWrapper>
        <FeaturePointWrapper id={id}>
          <FeaturePointTextWrapper reverse={reverse || false}>
            <FeaturePointHeader>{header}</FeaturePointHeader>
            {
          body.split('\\n').map((text) => (
            <FeaturePointBody>{text}</FeaturePointBody>
          ))
        }
          </FeaturePointTextWrapper>
          <FeaturePointImage reverse={reverse || false} src={image} />
        </FeaturePointWrapper>
      </FeaturePointOuterWrapper>
      <FeaturePointSeparator noSeparator={noSeparator || false} />
    </ScrollAnimation>
  );
};
export default FeaturePoint;
